import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {
  public innerWidth: any;
  fordesktop = false;
  formobile = false;
  displayClass: any;
  deviceClass: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  constructor() { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }

}
