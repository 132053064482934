import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  reviews: any[] = [];
  constructor() { }

  ngOnInit() {
    this.reviews = [
      {ID: 1, review_title: 'Project for Webgenius', review_content: '"Webeteer has been a pleasure to work with. This is the second time around to build my website and he is such a professional and perfectionist. Super responsive and fast. Thank you!"', author: 'juliab888 ',profile_img: 'profile_logo_7640316.jpg',review_rate: '5.0'},
      {ID: 2, review_title: 'Project for Webgenius', review_content: '"Webeteer was an absolute delight to work with! Extremely communicative and very patient with all of my changes and requests. I will definitely work with him again in the future!"', author: 'amyjdickens ',profile_img: 'profile_logo_15801839.jpg',review_rate: '5.0'},
      {ID: 3, review_title: 'Review Comparison report Website ', review_content: '"good communication which is key to getting these freelancer project down."', author: 'tbryson ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 4, review_title: 'Interviewee for Manchester Business School students (skype interview)', review_content: '"the students appriciated your insides, thank you"', author: 'erikbeulen ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 5, review_title: 'Expanding "WooCommerce Layered Nav Filters" widget', review_content: '"Webeteer was brilliant throughout the process, he went above and beyond some of the work required. Would highly recommend."', author: 'banksmediauk  ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 6, review_title: 'Help with adding a few pages and so on my site!', review_content: '"Awesome guy! Will be continuing working with Webeteer for continuation of my project!"', author: 'cadymacias  ',profile_img: 'profile_logo_17102602.jpg',review_rate: '5.0'},
      {ID: 7, review_title: 'Drivers On Call', review_content: '"With the ease and efficiency of a true professional, Webeteer insight and expertise helped me turn my ideas into reality.Thank you so much Webeteer for making my project a delightful process."', author: 'Ungarprod  ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 8, review_title: 'Donate Website with Unique Registration', review_content: '"Nice project with Webgenius. I will use again I am sure."', author: 'sundried  ',profile_img: 'profile_logo_14890447.jpg',review_rate: '5.0'},
      {ID: 9, review_title: 'www,FattyCrabs.com', review_content: '"He is very professional, competent and fast. He resolve my issue and I am a very happy person. No fuss. Highly recommend him to anyone, anytime."', author: 'francoyong',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 10, review_title: 'Kumdo Website Design Concept Only', review_content: '"2nd Job, more to come for Webeteer @ Webgenius. He is always professional and reliable. Can not wait to see his future work."', author: 'HapkidoCanberra   ',profile_img: 'profile_logo_3305177.jpg',review_rate: '5.0'},
      {ID: 11, review_title: 'Project for Webgenius', review_content: '"As always he ws great and responsive."', author: 'NVHC    ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 12, review_title: 'Redevelop our company website and add a shop', review_content: '"Webeteer was FANTASTIC, responsive to our requirements, acted fast and on time. Hire him again without a second thought."', author: 'HapkidoCanberra',profile_img: 'profile_logo_3305177.jpg',review_rate: '5.0'},
      {ID: 13, review_title: 'Build a Website', review_content: '"Absolute rock star. Can not recommend Webgenius enough! He is the very reason this website is a success. I will be hiring him again in the future!"', author: 'thoughtboxmedia',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 14, review_title: 'WEB DESIGNER WANTED!', review_content: '"Webeteer is awesome ! I now know why he is known as the webgenius. Webeteer took my very rough scope of works and turned it into a first class website with loads of functionality. Dont delay , hire him now !"', author: 'Kudos74',profile_img: 'profile_logo_16103892.jpg',review_rate: '5.0'},
      {ID: 15, review_title: 'Vbulletin Upgrading / Customization', review_content: '"Customized & made forum mobile friendly. Great communication & fast responses. Pleasure working with Webgenius."', author: 'thriller1',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 16, review_title: 'General Administration', review_content: '"Amazing guy, really appreciate everything he did for me. Great work, dependable, easy to get along with, A Quality work."', author: 'Shane P.',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 17, review_title: 'website for healthychildren', review_content: '"Incredible work. Fast, great quality and an absolute pleasure to work with. We will definitely use them again."', author: 'HealthyChildren ',profile_img: 'profile_logo_13700291.jpg',review_rate: '5.0'},
      {ID: 18, review_title: 'Build a Website', review_content: '"Absolutely marvelous work!!! Such a wonderful human being, a pleasure to work with, so professional, such a beautiful website! We are so pleased! Will definitely hire again and recommend to others!!!"', author: 'Maesyn',profile_img: 'profile_logo_13133488.jpg',review_rate: '5.0'},
      {ID: 19, review_title: 'Build an Online Store like Aloha.com', review_content: '"Just go to our website. Mikes work speaks for itself, pinkstorksolutions.com. Incredible freelancer, really look forward to working with him very frequently in the future. Great person, amazing worker, can not say enough good things about him!"', author: 'thomasupchurch ',profile_img: 'profile_logo_10446244.jpg',review_rate: '5.0'},
      {ID: 20, review_title: 'Convert a Template to a Website ', review_content: '"Michael is awesome and worked to get us pixel perfect. Love the enthusiasm and passion he brings o the project."', author: 'herojobs ',profile_img: 'profile_logo_15479209.jpg',review_rate: '5.0'},
      {ID: 21, review_title: 'Australian wordpress site ', review_content: '"Even though we had some misunderstanding about the project in the beginning Webgenius just kept on developing on the project untill I was satisfied."', author: 'showoffmedia ',profile_img: 'profile_logo_9607736.jpg',review_rate: '5.0'},
      {ID: 22, review_title: 'A security Breach ', review_content: '"Pro, couldnt get better, faster, or more responsive, if you want english speaking fast programming, dont hestitate one more second get this guy!"', author: 'Shane P ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 23, review_title: 'Build an escrow website for dinning/bar reservations ', review_content: '"One of the best freelancers to work with. He is available almost 24/7 and will work day and night to meet urgent deadlines."', author: 'Globe2globe ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 24, review_title: 'Create a Wordpress Template', review_content: '"I have worked with several good freelancers here at Freelcancer.com but I can assure you that Webgenius is the best one I had the pleasure to work with. Smart, fast and quality work in one pack."', author: 'Roderswiss ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 25, review_title: 'Contact form Fixes for Website', review_content: '"Excellent work as usual!"', author: 'Davide001 ',profile_img: 'profile_logo_281486.jpg',review_rate: '5.0'},
      {ID: 26, review_title: 'HTML5 Slider', review_content: '"Again excellent work from Webgenius ;-)"', author: 'showoffmedia ',profile_img: 'profile_logo_9607736.jpg',review_rate: '5.0'},
      {ID: 27, review_title: 'Convert email signauture to HTML', review_content: '"Very professional - very good communicator, very easy to work with!"', author: 'mortendragheim ',profile_img: 'profile_logo_12998241.jpg',review_rate: '5.0'},
      {ID: 28, review_title: 'I need one extra function in Facebook fan page', review_content: '"Perfect work :)"', author: 'Masterpeter ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 29, review_title: 'find the bug in the codignight code', review_content: '"found "bug" he was hired to find and fix before we expected"', author: 'DBR69 ',profile_img: 'profile_logo_10730492.jpg',review_rate: '5.0'},
      {ID: 30, review_title: 'Freelancer who can help correctning CSS and PHP for different wordpress', review_content: '"very fast and just as we agreed upon ;-)"', author: 'showoffmedia  ',profile_img: 'profile_logo_9607736.jpg',review_rate: '5.0'},
      {ID: 31, review_title: 'Wordpress: Error establishing a database connection Template', review_content: '"I posted the gig, award the project and the job was done in 10 minutes. Incredible!"', author: 'Strateka',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 32, review_title: 'Fixes on AM.ca', review_content: '"I had to duck delivery was so fast"', author: 'Urbanana',profile_img: 'profile_logo_1256164.jpg',review_rate: '5.0'},
      {ID: 33, review_title: 'Add and revise pages to exisiting Wordpress site', review_content: '"Mike was always pleasant to talk to and phone calls were by far the best way to make sure we were all on the same page. You dont have to worry about motivation from Mike as he is always excited to get started and keep the project moving along."', author: 'flyingremodeler ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 34, review_title: 'Build a new website', review_content: '"He did great job for us."', author: 'tonysit',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 35, review_title: 'Reinstall Hacked Wordpress Website', review_content: '"Best provider on Freelancer. Never disappointed!"', author: 'Davide001 ',profile_img: 'profile_logo_281486.jpg',review_rate: '5.0'},
      {ID: 36, review_title: 'Fix Wordpress Slider', review_content: '"Excellent worker and communication."', author: 'cmowry',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 37, review_title: 'Custom cart CSS to be migrated to Woocommerce (CSS only)', review_content: '"5 stars across the board. Mike was such a great find. Will definitely give him first consideration for any future projects."', author: 'Urbanana',profile_img: 'profile_logo_1256164.jpg',review_rate: '5.0'},
      {ID: 38, review_title: 'Adding Captcha to Contact Form', review_content: '"Amazing service once again! Always pulls through for me! A++ [02 February, 2015] Amazing service, once again! WebGeniuz pulls through everytime! A+"', author: 'Davide001 ',profile_img: 'profile_logo_281486.jpg',review_rate: '5.0'},
      {ID: 39, review_title: 'Some Minor Updates, Tweaks/Tuneup', review_content: '"WebGeniuz is just that a Genius when it come to websites. He is always on top of it doing more, faster than I could ever ask for. One of Freelancers best! Thank You! A+++"', author: 'damon1sl',profile_img: 'profile_logo_3864414.jpg',review_rate: '5.0'},
      {ID: 40, review_title: 'Changes for Wordpress Website', review_content: '"This guy is an absolute star! He really came through for me on a project and went beyond expectations. Thank you!"', author: 'Davide001',profile_img: 'profile_logo_281486.jpg',review_rate: '5.0'},
      {ID: 41, review_title: 'Build a Website', review_content: '"Quality of the work for both websites was first class. Michael, always was prompt in his replies and communication. I am happy with the delivery time, because I added more work and we added content it took longer than planned but that was not Michaels fault"', author: 'craneco',profile_img: 'profile_logo_13108252.jpg',review_rate: '5.0'},
      {ID: 42, review_title: 'Build a Magento Online Store', review_content: '"Working with Mike has been a great experience. The final website surpassed my expectations in design and functionality. Very professional and very capable of making things happen."', author: 'amaduroh ',profile_img: 'profile_logo_11739885.jpg',review_rate: '5.0'},
      {ID: 43, review_title: 'Migrate Wordpress site into e commerce website', review_content: '"Very efficient Wish I was as efficient as this man"', author: 'andrewchrobok',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 44, review_title: 'Build a new website for a new financial advising business', review_content: '"Quality of the work for both websites was first class. Michael, always was prompt in his replies and communication. I am happy with the delivery time, because I added more work and we added content it took longer than planned but that was not Michaels fault"', author: 'craneco',profile_img: 'profile_logo_13108252.jpg',review_rate: '5.0'},
      {ID: 45, review_title: 'Help Maintain and Enhance WordPress Site- Ongoing', review_content: '"Mike has top notch technical and consulting skills. He was extremely professional and quite patient as the client had little Web savvy. He used his time efficiently and ensured that value was fully delivered. Top tier guy."', author: 'Sgavurin',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 46, review_title: 'Build an Online Store', review_content: ' "Mike was awesome. When I asked for something to be done, it was and very quickly. He had a great understanding of what I was looking for. I do plan on working with Mike again, thats a promise."', author: 'yduncan ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 47, review_title: 'Referral Farming', review_content: '"Excellent communication; very prompt getting back to me" ', author: 'free92128lancer',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 48, review_title: 'Build a Website Calculator', review_content: '"He is great to work with."', author: 'esalaroqica ',profile_img: 'profile_logo_13273902.jpg',review_rate: '5.0'},
      {ID: 49, review_title: 'Magento design, plug-in and error fixing', review_content: '"Michael has made my life much easier than any other developer I have worked with. I would highly recommend his services to all and hope I will be working with him for a while to come."', author: 'laptopwizard  ',profile_img: 'profile_logo_9404006.jpg',review_rate: '5.0'},
      {ID: 50, review_title: 'Custom Blog/News section.', review_content: '"I had Mike touch up my sites news section. It came out perfect like always!"', author: 'mm03',profile_img: 'profile_logo_3692579.jpg',review_rate: '5.0'},
      {ID: 51, review_title: 'Build a Website', review_content: '"well done great communication happy with our job"', author: 'Uniquephysiquesj',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 52, review_title: 'Crowdfunding Update', review_content: '"Second project with Mike, as expected he did incredible work again."', author: 'mm03 ',profile_img: 'profile_logo_3692579.jpg',review_rate: '5.0'},
      {ID: 53, review_title: 'Create Searchable Posts', review_content: '"Always a pleasure"', author: 'billsa',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 54, review_title: 'Add new functionalities to a WordPress Template', review_content: '"Excellent job. Good Quality. Recommend."', author: 'Trustme001',profile_img: 'profile_logo_524052.jpg',review_rate: '5.0'},
      {ID: 55, review_title: 'Build a Website', review_content: '"Thank you so much Mike!"', author: 'CampCockerCathy',profile_img: 'profile_logo_3654087.jpg',review_rate: '5.0'},
      {ID: 56, review_title: 'Website and Software', review_content: '"The Best Freelancer i met till now. I will hire him again soon for next project."', author: 'shamsi6020 ',profile_img: 'profile_logo_11713275.jpg',review_rate: '5.0'},
      {ID: 57, review_title: 'blog set up', review_content: '"Was very helpful and quick on the email."', author: 'francesdrummond',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 58, review_title: 'Custom Gift/Wish List', review_content: '"More than exceeded our goal. Great understanding and easy to work with"', author: 'billsa',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 59, review_title: 'Magento fulltime developer', review_content: '"This is the best guy I ever had the pleasure off working with! I would recommend him for every people! Super programming, good keeping deadlines, works nonstop! Hope to do projects with you again! [14 August, 2014] Excellent! Quick response, development just beautiful! "', author: 'dreamsparkio',profile_img: 'profile_logo_11825161.jpg',review_rate: '5.0'},
      {ID: 60, review_title: 'Create a Wordpress Event / Tournament Plugin', review_content: '"Mike did an incredible job on this project. He is extremely professional, and I will hire him again for any of my future projects."', author: 'mm03 ',profile_img: 'profile_logo_3692579.jpg',review_rate: '5.0'},
      {ID: 61, review_title: 'Completion of Website Redesign and PayPal Advanced Integration', review_content: '"Excellent work. Excellent skills. Excellent project management. Excellent communication. Overall an excellent and pleasurable experience. Will continue to use him going forward."', author: 'hcrosby ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 62, review_title: 'Revamp & Redesign a Blog', review_content: '"Great communication and professional. . Works with you on every detail to ensure the project is done to specifications. I would hire again"', author: 'DavidMLevy ',profile_img: 'profile_logo_11696788.jpg',review_rate: '5.0'},
      {ID: 63, review_title: 'Connect Woocommerce to web services portal and pass inventory & orders', review_content: '"Michael is a rockstar developer, did an awesome job with our project, will be hiring again. lightning fast responses. and best of all self starter."', author: 'bx3800',profile_img: 'profile_logo_3503861.jpg',review_rate: '5.0'},
      {ID: 64, review_title: 'Build an Online Store', review_content: '"Mike is just an absolute GEM! Mike is professional, excellent at communication and very efficient developer. You can hire him with your closed eyes. Thanks Mike for your help."', author: 'Rahul A',profile_img: 'profile_logo_11555910.jpg',review_rate: '5.0'},
      {ID: 65, review_title: 'Build a Website', review_content: '"Highly satisfied with the projects results. Michael knows his business."', author: 'Kyrylo K',profile_img: 'profile_logo_11454437.jpg',review_rate: '5.0'},
      {ID: 66, review_title: 'Wordpress Slider + 100 Pages', review_content: '"Honestly, probably the best freelancer I have worked with... whipped through a pretty complex problem with great speed and superb quality, no fix was too much hassle for Mike. Awesome"', author: 'chrisbhurrut',profile_img: 'profile_logo_5858630.jpg',review_rate: '5.0'},
      {ID: 67, review_title: 'Integrate Stripe onto my site', review_content: '"Its all in the name, WEBGENIUZ! I chose Mike because he came recommended by freelancer. I am very glad I did as he took my dream and made it a reality. Great guy, great developer!"', author: 'kawizx62003 ',profile_img: 'profile_logo_4423042.jpg',review_rate: '5.0'},
      {ID: 68, review_title: 'Upgrade a website', review_content: '"Very easy to work with and very professional. Highly recommended"', author: 'Flightbeam ',profile_img: 'profile_logo_11464041.jpg',review_rate: '5.0'},
      {ID: 69, review_title: 'Fix Scripts on Website', review_content: '"So fast and so very helpful. Thank you for your hard work!!"', author: 'vintoncomputer  ',profile_img: 'profile_logo_9243020.jpg',review_rate: '5.0'},
      {ID: 70, review_title: 'Create a Joomla Template', review_content: '"highly recommended"', author: 'timbo83',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 71, review_title: 'Improve existing website design -- 2', review_content: '"Top quality work!"', author: 'danrevah',profile_img: 'profile_logo_10658732.jpg',review_rate: '5.0'},
      {ID: 72, review_title: 'Write some Software', review_content: '"Mike has been a pleasure all the way. Excellent problem solver. Highly recommend!"', author: 'premierboxbreaks',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 73, review_title: 'Tab delimited import/export', review_content: '"This project has been a breeze thanks to the hard work done by Mike. Cant wait to work on the next project! [30 April, 2014] Mike is great. He grasped the concept of our project straight away and delivers excellent work. [15 May, 2014] What can I say Mike has done everything I asked and more. Great to work with!"', author: 'ryangest',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 74, review_title: 'Customize Wordpress theme', review_content: '"Excellent communication and excellent work! A++++"', author: 'nyybc21   ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 75, review_title: 'Build an Online Store', review_content: '"Excellent to work with, I have yet to deal with anyone else as professional and efficient as this. ***** 5 stars all the way"', author: 'whitetailink',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 76, review_title: 'Modify a WordPress Theme for new Responsive Home Page Design', review_content: '"Mike is the best! He worked hard to figure out and solve all the issues with my challenging website. I will continue to hire and highly recommend him!"', author: 'carlschneider121 ',profile_img: 'profile_logo_10791585.jpg',review_rate: '5.0'},
      {ID: 77, review_title: 'Build a Website', review_content: '"Mike was very easy to work with and delivered what we needed!"', author: 'archiegupta',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 78, review_title: 'Build an Online Store', review_content: '"what a top guy! been very good to me. supported my project all the way to the end. recommended!"', author: 'digitalsakurauk',profile_img: 'profile_logo_2962112.jpg',review_rate: '5.0'},
      {ID: 79, review_title: 'Stroke Website Development', review_content: '"Mike is astonishingly professional: from the very start of his commission he created a number of clever and lucid solutions to the multi-level request asked of him, which culminated in a mutuallly agreeable plan for progression. Then he just went ahead and completed everything, stage by stage, keeping me filled in during every step of the way - everything was negotiated in a most amicable and clever way. The work is first class. I will hire this freelancer again and recommend him to anyone - I am lucky to have found him!"', author: 'tombal123',profile_img: 'profile_logo_7970847.jpg',review_rate: '5.0'},
      {ID: 80, review_title: 'Build a Website - EL', review_content: '"Worked really hard to deliver the project on time, much appreciated!"', author: 'awaterman100',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 81, review_title: 'Photo Contest with Wordpress', review_content: 'Photo Contest with Wordpress', author: 'AvailableLight ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 82, review_title: 'Wordpress plugin creation or modification', review_content: '"Was a pleasure working with Mike. The task was done with professionalism and speed. My client was please and so was I. I would do business with him again and very soon."', author: 'Kimo33 ',profile_img: 'profile_logo_138564.jpg',review_rate: '5.0'},
      {ID: 83, review_title: 'Landing Page', review_content: '"Very good. Will hire again for other jobs."', author: 'adstractsolution ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 84, review_title: 'Build a Website - NautiFish Rum Bar', review_content: '"you found the best - HIRE MIKE TODAY - you have no idea what kind of favor you are doing yourself."', author: 'nautifishrumbar',profile_img: 'profile_logo_10255990.jpg',review_rate: '5.0'},
      {ID: 85, review_title: 'WordPress website development', review_content: '"Great work, and just as important, very responsive with follow-up and revisions. I recommend this guy is work without reservation. The only delays were from our own responses, not his."', author: 'danielabrowning',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 86, review_title: 'Build a secure online form with a small backend', review_content: '"Mike is very competent, professionnal and friendly! I recommend him to everyone here!"', author: 'chrislavigneqc',profile_img: 'profile_logo_9534131.jpg',review_rate: '5.0'},
      {ID: 87, review_title: 'adding an online store to my existing website', review_content: '"Mike was great! Very professional, friendly and supportive. Patient at times too. He completed the work very fast and kept me updated as we went along. I would definitely recommend him. Thanks Mike!"', author: 'yulia001',profile_img: 'profile_logo_4348532.jpg',review_rate: '5.0'},
      {ID: 88, review_title: 'Rainbow Website', review_content: '"The man!"', author: 'Rainbowmarketing ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 89, review_title: 'Create a Wordpress Template', review_content: '"Mike was such a pleasure to work with. I look forward to a new project - his insight, professionalism, structure and humor made the project a success."', author: 'ajaeger21',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 90, review_title: 'Convert a Template to a Website - YouTube Streaming Page', review_content: '"It was a great experience working with Mike. He was able to get all of the tasks done quickly and was very professional. He went above and beyond what the minimum requirement whereas other freelancers have tried to nickel and dime us. Hire this guy!"', author: 'Five14Church',profile_img: 'profile_logo_10190374.jpg',review_rate: '5.0'},
      {ID: 91, review_title: 'Website Calendar', review_content: '"MIke was great, able to complete all requests and late changes to scope. nothing was a problem. Highly recommend."', author: 'timbo83',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 92, review_title: 'WordPress website redesign', review_content: '"Excellent freelancer!"', author: 'kathy2006',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 93, review_title: 'Website Designer', review_content: '"I had my doubts when using Freelancer.com for the first time. WebGeniuz changed all that. Mike and his crew worked with me getting the site to the level of detail I wanted and completed the job earlier then I anticipated. If I had questions or needed to talk to him he would just give me a phone call. GREAT SERVICE, DEFINITELY RECOMMENDED!"', author: 'vinny87',profile_img: 'profile_logo_9967495.jpg',review_rate: '5.0'},
      {ID: 94, review_title: 'Need Form Programmed', review_content: '"Great freelance programmer. Very professional and great communication."', author: 'pmanager33',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 95, review_title: 'Custom development for Wordpress site', review_content: '"Hire this guy ASAP! He fixed everything perfectly in record-time and was a joy to work with...cannot recommend highly enough."', author: 'clanko',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 96, review_title: 'Model Agency Plugin issueI', review_content: '"Seriously! Hire this lad. He is so incredibly easy to work with, quick and knowledgable."', author: 'nicolaas1smith',profile_img: 'profile_logo_8217597.jpg',review_rate: '5.0'},
      {ID: 97, review_title: 'Add functionality to earlier created event system as discussed.', review_content: '"Perfect service as always ;)! Thanks Michael!"', author: 'WebKrunch',profile_img: 'profile_logo_7093931.jpg',review_rate: '5.0'},
      {ID: 98, review_title: 'Add coupon codes to Wordpress plugin', review_content: '"Great as always - reliable, credible - fantabulous!!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 99, review_title: 'Wordpress Plugin Product & Category Page', review_content: '"Great job!"', author: 'LanaInternet',profile_img: 'profile_logo_8045358.jpg',review_rate: '5.0'},
      {ID: 100, review_title: 'Wordpress skills needed for Wedding Photographer', review_content: '"Did a fantastic job and would highly recommend."', author: 'fergusonweddings',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 101, review_title: 'Fix search engine on website', review_content: '"Love his work"', author: 'signupiceland',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 102, review_title: 'small wordpress job need it done now', review_content: '"Not a big job, but an important one for me. I added a couple of extra bits.. which were done without fuss or fan fare. nice. stoked"', author: 'richardmclachlan',profile_img: 'profile_logo_7619961.jpg',review_rate: '5.0'},
      {ID: 103, review_title: 'Make Changes to A Wordpress Theme', review_content: '"Amazing! Got the job done very quickly and is definitely a "genius". Knows exactly what he is doing. Best freelancer we have worked with so far."', author: 'ishq294',profile_img: 'profile_logo_9460023.jpg',review_rate: '5.0'},
      {ID: 104, review_title: 'wp royal slider fix', review_content: '"FANSTASTIC WP guy! WebGeniuz quickly fixed the issue and communication was awesome. Thanks so much!"', author: 'MT2008 ',profile_img: 'profile_logo_788705.jpg',review_rate: '5.0'},
      {ID: 105, review_title: 'Countdown Timer + Button Apperance', review_content: '"This has been been probably the most professional and caring outsourcer that i have ever worked with. I definitely recommend him with 5 stars! He was on time and kept me updated on everything!"', author: 'mzjuan34 ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 106, review_title: 'Maximize Wootheme Navigation and CSS updates', review_content: '"Very communicative throughout the project and willing to suggest improvements and different ways of doing things that I had not considered."', author: 'EssexConsultants ',profile_img: 'profile_logo_6333959.jpg',review_rate: '5.0'},
      {ID: 107, review_title: 'Woocommerce Integration', review_content: '"OMG - I was VERY impressed. WebGeniuz is REALLY a genius! Absolutely great to work with. Will definitely hire again!"', author: 'bartok00',profile_img: 'profile_logo_2726281.jpg',review_rate: '5.0'},
      {ID: 108, review_title: 'Build a platform to receive leads in PHP', review_content: '"Great service and very easy to communicate with. Completed task to requirements and will definitely use again in the future - 5 Stars *****"', author: 'peterwoods',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 109, review_title: 'Law firm web site design', review_content: '"He did an amazing job putting together my new web site, which was exactly according to my requested specifications. And, he worked faster than I ever expected and consistently gave me updates throughout the entire process of building the site. I highly recommend him! [03 November, 2013] Incredibly attentive in all respects, and he put together an amazing site for me in hardly any time at all. You can\'t go wrong with him!"', author: 'pdxone',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 110, review_title: 'Private Project - Upgrade last script', review_content: '"Great developer! always on time! very professional"', author: 'nobelagency ',profile_img: 'profile_logo_2865389.jpg',review_rate: '5.0'},
      {ID: 111, review_title: 'Simple price & quantity multiplier for wordpress site - repost', review_content: '"great guy to work with. communicated 100% the whole way and did exactly what was needed of him. definitely will hire again for future projects."', author: 'flopdnuts',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 112, review_title: 'Wordpress site integration with simple ecommerce', review_content: '"Fast, efficient, great communicator. I cant say enough good. Even though this wasnt a large project, it saved me hours of frustration. Definitely will be looking him up for work in the future. All thumbs up. Great, great job!"', author: 'Rcflyer30 ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 113, review_title: 'Fix bug in php script - repost', review_content: '"Excellent freelancer! Quick. communative and very helpful. Would definately re-hire in the future."', author: 'necrovaris',profile_img: 'profile_logo_1579779.jpg',review_rate: '5.0'},
      {ID: 114, review_title: 'Create a woocommerce plugin to show events.', review_content: '"Great job once again! Thanks for helping me out man!"', author: 'WebKrunch',profile_img: 'profile_logo_7093931.jpg',review_rate: '5.0'},
      {ID: 115, review_title: 'Help with two Wordpress projects', review_content: '"Great work as always. Top freelancer!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 116, review_title: 'Website Updates & Tweaks - ongoing work', review_content: '"Mike hits the ball out of the park every single time! Love this guy! [12 November, 2013] Another great project from Mike - He just rips through work like a bull in a China shop! I will continue to use him as long as I can A+! [14 November, 2013] Outstanding as usual! Mike\'s a winner with a strong work ethic! [21 November, 2013] Awesome, Awesome & Awesome! [27 November, 2013] Mike is a gem and I hope no one else finds about about him! [05 December, 2013] Mike is just like the Duracell Bunny, he just keeps on ticking! A++++ As usual! [16 December, 2013] Always an outstanding pleasure to work with this WebGeniuz A+! [08 January, 2014] I love using this Freelancer. I hope he never leaves cause he\'s my go to guy for everything online! [04 February, 2014] Mike just keeps on delivering the goods every single time! Doesn\'t get any better that this A+ [06 February, 2014] Awesome, Awesome, Awesome! "', author: 'damon1sl',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 117, review_title: 'Website Updates & Tweaks', review_content: '"Hands down the best Freelancer I have worked with to date. Mike is just on it, fast, great communication, perfect English and did more than I asked for. I dont even want to share him! A+"', author: 'damon1sl',profile_img: 'profile_logo_3864414.jpg',review_rate: '5.0'},
      {ID: 118, review_title: 'Redirect to Entry page once every 15 days using cookies', review_content: '"So freaking fast and well done. Friendly and on point. Will hire again."', author: 'nicolaas1smith',profile_img: 'profile_logo_8217597.jpg',review_rate: '5.0'},
      {ID: 119, review_title: 'Fix CSS / Look of wordpress theme & woocommerce', review_content: '"Absolutely perfect. Issue was fixed - communication was excellent. Nothing more to say!"', author: 'WebKrunch',profile_img: 'profile_logo_7093931.jpg',review_rate: '5.0'},
      {ID: 120, review_title: 'upgrade on "FB API Private Project"', review_content: '"Great developer! professional and on time! as usual! 5 Stars! will hire again!"', author: 'nobelagency',profile_img: 'profile_logo_2865389.jpg',review_rate: '5.0'},
      {ID: 121, review_title: 'Custom Search Bar', review_content: '"Great Lad, Knows his work very well! Recommended! I will hire him again in future."', author: 'wemakedesign',profile_img: 'profile_logo_2820567.jpg',review_rate: '5.0'},
      {ID: 122, review_title: 'auto clear cache on homepage of PHP site', review_content: '"We are working on other projects right now"', author: 'LiveFishDirect',profile_img: 'profile_logo_7361562.jpg',review_rate: '5.0'},
      {ID: 123, review_title: 'FB API Private Project', review_content: '"5 stars! Professional and fast developer! will hire again!"', author: 'nobelagency ',profile_img: 'profile_logo_2865389.jpg',review_rate: '5.0'},
      {ID: 124, review_title: 'I need a WordPress Theme Installed to Demo Version on my site.', review_content: '"Will and going ot do more work with him."', author: 'armortelevision',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 125, review_title: 'Film Makers Crowd Sourcing Site', review_content: '"Michael is a true professional and an absolute pleasure to work with. Great communication, great ideas, fast work. Will definitely work with him again! I am very happy that I went with him as my freelancer for my complex website."', author: 'physicianwriter7 ',profile_img: 'profile_logo_4036432.jpg',review_rate: '5.0'},
      {ID: 126, review_title: 'Need fix bug on menu', review_content: '"perfect freelancer , i will work with it again"', author: 'lolo1080',profile_img: 'profile_logo_2916857.jpg',review_rate: '5.0'},
      {ID: 127, review_title: 'Small Ipad/Iphone JS issue on closing a popup in a wordpress site', review_content: '"Very fast work on an issue other programmers had a hard time on fixing (as in: not getting things fixed), nice personal approach. Iam happy."', author: 'baloga',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 128, review_title: 'Responsive website for entering promotional codes', review_content: '"Always reliable - always a pleasure to work with. Thank you for your continued hard work Mike!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 129, review_title: 'Write a scripet that will Export data using Facebook Graph API', review_content: '"HIGHLY professional developer! FAST! and has great knowledge in PHP in particular! Will hire again!"', author: 'nobelagency',profile_img: 'profile_logo_2865389.jpg',review_rate: '5.0'},
      {ID: 130, review_title: 'Change Colours on 2 Website Background Images', review_content: '"Mike was a pleasure to work with. Completed the project really fast and provided great and friendly communication throughout. A++++"', author: 'utopiagrange',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 131, review_title: 'More edits to a custom Wordpress plugin', review_content: '"Reliable, hard working, friendly - makes all the hard work i give him look easy! Thanks again Mike!!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 132, review_title: 'contact form 7 wordpress customise - repost', review_content: '"A highly talented, hard working, gifted individual who kept to ALL of our objectives and delivered in super fast time. From the start Mike was keeping us posted on developments, making his expert suggestions and constantly looking to improve our freelance experience. I really cant rate Mike highly enough in all the categories. Would I hire again? I simply would not waste my time elsewhere. Thanks Mike"', author: 'axiomonline1 ',profile_img: 'profile_logo_7264492.jpg',review_rate: '5.0'},
      {ID: 133, review_title: 'Get the emails working on our Magento store + small Wordpress fix', review_content: '"As Tina Turner said - "Simply the best!" - reliable and a true professional. Everything you could want from a freelancer."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 134, review_title: 'Inputting codes into a promotional Wordpress website', review_content: '"Super reliable - top quality work - always a pleasure to work with."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 135, review_title: 'Magento and Wordpress Tweaks', review_content: '"The usual high quality, fast and professional work i have come to expect from WebGeniuz! Thank you for your continued hard work Mike!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 136, review_title: 'Additional features to a Wordpress website - repost', review_content: '"Reliable professional service as always."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 137, review_title: 'Amends to form on a Wordpress website', review_content: '"Amazing as usual! I really cant fault this guys work - he is simply the best!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 138, review_title: 'creative sopping webpage', review_content: '"very good job"', author: 'faizalhussayen ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 139, review_title: 'Escort agenecy (adult) web design and compnonent - repost', review_content: '"This guys is freaking AMAZING!!! he finished way before the deadline and his graphics are amazing and hes always communicating and hes just awesome hey... hire him!!!"', author: 'tashielb',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 140, review_title: 'Import posts to Wordpress', review_content: '"Fantastic work provided by WebGeniuz as always."', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 141, review_title: 'Wordpress Plugin Extending the Comment System Functionality', review_content: '"One of the best developers I have ever worked with in my 8 year career in ad agencies! Great communication, knowledge is robust, and the attitude was top notch."', author: 'websitedelux',profile_img: 'profile_logo_3710985.jpg',review_rate: '5.0'},
      {ID: 142, review_title: 'Help with contact form on website', review_content: '"Very professional and easy to work with, Thanks Mike!!! Looking forward to lots more. Stay Blazed."', author: 'stonerdays',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 143, review_title: 'Upgrade our version of Magento', review_content: '"Excellent work as always - never a frown when WebGeniuz is in town ;-)"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 144, review_title: 'Tweaks to Magento Navigation', review_content: '"The perfect freelancer - on time, on budget - and an excellent job done!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 145, review_title: 'Custom Wordpress Plugin', review_content: '"From custom Wordpress Plugins to Full Magento Stores - this Freelancer is a must have developer!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 146, review_title: 'Create a mobile website from existing website via www.dudamobile.com', review_content: '"First Class. Reliable. Great Perseverance. Great post job support. 100% satisfied"', author: 'TPS44',profile_img: 'profile_logo_6739540.jpg',review_rate: '5.0'},
      {ID: 147, review_title: 'website reboot for web', review_content: '"excellent work as usual. pleasure to work with."', author: 'ecksman ',profile_img: 'profile_logo_1318632.jpg',review_rate: '5.0'},
      {ID: 148, review_title: 'Menu moved and customised', review_content: '"Really pleased with the service. I was able to easily communicate on Freelancer and Skype, and the project was finished exactly the way I needed it!"', author: 'TerraformNet',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 149, review_title: 'Custom Wordpress Edits', review_content: '"Superb freelancer, cant recommend this guy enough. Great communication, professional workmanship."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 150, review_title: 'Simple Wordpress Page Creation Project - URGENT!', review_content: '"Awesome work on the project and great communication throughout the entire process. Delivered on time and was a great pleasure to work with. Highly recommend A+"', author: 'yobaby08',profile_img: 'profile_logo_796221.jpg',review_rate: '5.0'},
      {ID: 151, review_title: 'Custom edits to Magento and Wordpress themes', review_content: '"Outstanding work by an outstanding developer! Cant praise this guy enough!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 152, review_title: 'profile_logo_4197929.jpg', review_content: '"Mike is outstanding...he is now a Perm. member of my staff. Great design, original and eager to please. He already has another job of mine. Dont make him too busy though, I need him to do my work too."', author: 'hyattmarketing  ',profile_img: 'profile_logo_7427744.jpg',review_rate: '5.0'},
      {ID: 153, review_title: 'Custom Project May 23 2013 22:35:19', review_content: '"My one stop developer. Absolutely fantastic."', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 154, review_title: 'Custom Project May 21 2013 16:54:53', review_content: '"Great developer and friend - I am struggling to come up with new ways to say how great he is - the fact that i keep working with him should be testament enough :) he truly is the best developer i have worked with."', author: 'mmfnduk ',profile_img: 'profile_logo_7427744.jpg',review_rate: '5.0'},
      {ID: 155, review_title: 'Custom Project May 13 2013 14:55:24', review_content: '"Reliable every time! Wonderful working relationship we have going on, long may it continue!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 156, review_title: 'Custom Project May 09 2013 20:38:35', review_content: '"At the risk of repeating myself - this guy is fantastic, hardworking, helpful and great to work with. Onto the next project!"', author: 'mmfnduk',profile_img: 'profile_logo_7427744.jpg',review_rate: '5.0'},
      {ID: 157, review_title: 'Custom Project May 08 2013 20:42:33', review_content: '"As always exceptional work - reliable, high quality, i cant speak highly enough of Mike - he is a fantastic Developer and i look forward to working with him each time."', author: 'mmfnduk',profile_img: 'profile_logo_7427744.jpg',review_rate: '5.0'},
      {ID: 158, review_title: 'e-Commerce web build', review_content: '"This project was dusted in style: "DONZO"!"', author: 'Infinitas',profile_img: 'profile_logo_1740820.jpg',review_rate: '5.0'},
      {ID: 159, review_title: 'website enhancements needed!', review_content: '"Great freelancer, goes above and beyond to make your expectations a reality. I would hire again!"', author: 'netis1213',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 160, review_title: 'Custom Project 04.29.13', review_content: '"As always a pleasure to work with Mike. Highly recommended."', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 161, review_title: 'Custom Project Apr 27 2013 17:16:56', review_content: '"This young man is a gem. No doubt someday he will be in control of something very successful. I will use him again in future projects."', author: 'vautour',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 162, review_title: 'Website design and coding', review_content: '"Great Freelancer and very useful in making improvement suggestions. Plan to rehire for future projects."', author: 'kcstudiovw',profile_img: 'profile_logo_6476856.jpg',review_rate: '5.0'},
      {ID: 163, review_title: 'Custom Project Apr 22 2013 18:47:26', review_content: '"Unquestionably the best developer I have had the pleasure of working with! Dont hesitate to hire him - i guarantee you wont be sorry."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 164, review_title: 'Design a unique website for my holistic brand.', review_content: '"Super friendly and easy to talk to, works very quickly and efficiently, and was patient with all my requests and changes. Website was up faster than I thought, and is beautiful. Continued working with me afterwords to help me understand how to use the site. Overall, a wonderful experience. I am so glad I chose to work with him!"', author: 'jessicamcc',profile_img: 'profile_logo_7284341.jpg',review_rate: '5.0'},
      {ID: 165, review_title: 'Program a PSD we have designed into Magento - URGENT', review_content: '"Mike was great! very professional, patient and hard working!"', author: 'UOS',profile_img: 'profile_logo_2952174.jpg',review_rate: '5.0'},
      {ID: 166, review_title: 'Custom Project Apr 14 2013 00:15:23', review_content: '"Super friendly & got the job done...made my first freelancer experience postitive! Thank you!"', author: 'Didit2013',profile_img: 'profile_logo_7299790.jpg',review_rate: '5.0'},
      {ID: 167, review_title: 'Wordpress custom events page - repost', review_content: '"Again, great work. Good communication, everything exactly as I requested. A++"', author: 'SparkCreative ',profile_img: 'profile_logo_1407498.jpg',review_rate: '5.0'},
      {ID: 170, review_title: 'Custom Project Apr 11 2013 10:41:53 - repost', review_content: '"Beautiful work and coding again from the WebGeniuz - WedGeniuz by name and WebGenius by trade!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 171, review_title: 'Wordpress excerpt limited to first few characters of first paragraph', review_content: '"Without fail WebGeniuz solves all of our problems - nothing too big or two small, he has come to our rescue again and again. I look forward to working on our next project together."', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 172, review_title: 'Wordpress search full words only', review_content: '"Outstanding work again from my number 1 goto developer. I cannot recommend this guy enough! Highest quality work and friendly personality - perfect mixture."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 173, review_title: 'Invoice amendment', review_content: '"Super. Will work with him again!"', author: 'Gloqui',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 174, review_title: 'Magento Store', review_content: '"WebGeniuz is consistent - his knowledge is second to none. We have been working together now for some time and the quality is always the highest possible. I have used other Freelancers - but none have been as professional, fast acting and friendly. I cannot recommend Mike enough!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 175, review_title: 'Make wordpress slider auto-slide and also loop', review_content: '"One of the best. Did a great job even when problems arose. Good communication and held to a very high quality standard. Highly Recommended!!!!"', author: 'Mashup',profile_img: 'profile_logo_2147839.jpg',review_rate: '5.0'},
      {ID: 176, review_title: 'PSD to Wordpress theme 6', review_content: '"Great person and developer, he works hard!"', author: 'alejandronfb',profile_img: 'profile_logo_4242611.jpg',review_rate: '5.0'},
      {ID: 177, review_title: 'Change Grid in magento website', review_content: '"Perfect. Fast, good communication. Very happy. Thanks WebGeniuz."', author: 'Gloqui ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 178, review_title: 'light website design (5-6 pages)', review_content: '"professional, reliable, fast, accurate, patient: the person to hire if you need a website!!"', author: 'smitt23',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 179, review_title: 'Magento Template Expert to create our new site.', review_content: '"Top notch service, effort and a job well done. Would highly recommend WebGeniuz to anyone that wants a hassle free experience on Freelancer. Duane"', author: 'DuaneCalvin',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 180, review_title: 'Website redesign - creation', review_content: '"It was a pleasure working with WebGeniuz and would definitely consider rehiring on future projects."', author: 'kcstudiovw',profile_img: 'profile_logo_6476856.jpg',review_rate: '5.0'},
      {ID: 181, review_title: 'Wordpress and Magento help.', review_content: '"Simply the best Freelancer I have every the pleasure of working with!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 182, review_title: 'Private project for Michael', review_content: '"Spot on once again, cant fault this guy - cheers dude."', author: 'Dale369 ',profile_img: 'profile_logo_1378764.jpg',review_rate: '5.0'},
      {ID: 183, review_title: 'Wordpress tweaks.', review_content: '"As always - fantastic job by Web Geniuz! On time, friendly, reliable... what more could anyone want?"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 184, review_title: 'A Adult membership site for model', review_content: '"This is a great freelancer, communication was fantastic."', author: 'WhizKidDesigns ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 185, review_title: 'Wordpress theme tweaks.', review_content: '"As always - highly professional service provided by Web Geniuz, he is our go to developer! If you are looking for help with Wordpress or Magento - dont look anywhere else - this guy is your man!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 186, review_title: 'HTML to Wordpress for Michael', review_content: '"Always a pleasure working with Michael, talented guy."', author: 'Dale369',profile_img: 'profile_logo_1378764.jpg',review_rate: '5.0'},
      {ID: 187, review_title: 'Website creation', review_content: '"I am brand new at this and WebGeniuz was very patient and very professional. I would definitely use him again. In fact, we are already working on giving him details for our next project. Al, Iguana Painting"', author: 'nordman63',profile_img: 'profile_logo_6848931.jpg',review_rate: '5.0'},
      {ID: 188, review_title: 'i need a gallery page on on my website. where people can upload their own photos..', review_content: '"Mike was awesome to work with.. TOP LEVEL communication for getting those tricky projects done when you need someone who understands your project!! Could not be happier with the result.. Mike is a star!"', author: 'fightclub1 ',profile_img: 'profile_logo_1686720.jpg',review_rate: '5.0'},
      {ID: 189, review_title: 'Homepage needs to move to Wordpress page', review_content: '"Hands down the best freelancer I have worked with on this website. I will only ever use Michael for any development projects I need. Incredible job. Well done!"', author: 'DigitalViLounge',profile_img: 'profile_logo_2085872.jpg',review_rate: '5.0'},
      {ID: 190, review_title: 'Wordpress Plugin Customization', review_content: '"As always outstanding work, professional attitude, I really cant recommend WebGeniuz enough. I look forward to our continuing work together!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 191, review_title: 'Fix buggy Wordpress widget', review_content: '"As always WebGeniuz comes up with the perfect solution to my problems - this one was a bit more of a challenge than my usual work, but i am very pleased to say that it was no problem for WebGeniuz and now my clients website is functioning as they wanted. Thanks for all your hard work as always!"', author: 'mmfnduk ',profile_img: 'profile_logo_1686720.jpg',review_rate: '5.0'},
      {ID: 192, review_title: 'Fix a glitch in our Magento Site', review_content: '"Mike is one of the best developers I have ever worked with. He was quick, communicated clearly, and gave me updates throughout. Great experience. Definitely will hire again."', author: 'Conor D.',profile_img: 'profile_logo_4054099.jpg',review_rate: '5.0'},
      {ID: 193, review_title: 'Converting 2 PSD files to HTML / CSS / JS ? etc...', review_content: '"Mike is the best freelancer that i have ever worked till now, i will use him for all of my project from now. I recommend him, you can use Mikes services with trust. A+++++"', author: 'forests99 ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 194, review_title: 'Top Choice Top Banner', review_content: '"As usual this freelancer is great to work with and delivers over the top results on time and on budget."', author: 'cforell ',profile_img: 'profile_logo_1619018.jpg',review_rate: '5.0'},
      {ID: 195, review_title: 'PSD to a child theme of another functioning theme.', review_content: '"Excellent service and great communication! Will use this freelancer again."', author: 'avistais ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 196, review_title: 'Customising Feed on Wordpress theme', review_content: '"It is not just a clever name - he truely is a web genius. Consistently high quality work, friendly and prompt - could ask for anything more. Since using this freelancer, i have not had to look anywhere else for help with my project. Thanks again - look forward to working with you again very soon!"', author: 'mmfnduk',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 197, review_title: 'Wordpress Theme / Javascript errors fix', review_content: '"As always WebGeniuz has provided me with the solution i needed within a tight deadline. I cannot recommend this developer enough - he is as talented as he is reliable. Look forward to working with you again and again!"', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 198, review_title: 'PSD to HTML5 Conversion', review_content: '"Great communication and great work = great freelancer! Will work with again! Thanks!"', author: 'Rustifer44',profile_img: 'profile_logo_600292.jpg',review_rate: '5.0'},
      {ID: 199, review_title: 'Wordpress custom events page', review_content: '"Excellent freelancer, knowledgable, friendly, great communication, super fast work, every amend dealt with as required. WIll definitely use again..."', author: 'SparkCreative ',profile_img: 'profile_logo_1407498.jpg',review_rate: '5.0'},
      {ID: 200, review_title: 'Google Map in Wordpress with +/- 150 locations', review_content: '"Excellent freelancer!"', author: 'nicktortike ',profile_img: 'profile_logo_1203546.jpg',review_rate: '5.0'},
      {ID: 201, review_title: 'Magento Freight Extension', review_content: '"Once again a true professional."', author: 'emmarks ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 202, review_title: 'Design a New Theme for Existing Magento Store', review_content: '"Best Freelancer EVER! Thank Mike."', author: 'emmarks ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 203, review_title: 'Scrollable Image - WOW slider required', review_content: '"Excellent service. On time, on budget and fast. Will use this provider again."', author: 'THEDOG ',profile_img: 'profile_logo_655550.jpg',review_rate: '5.0'},
      {ID: 204, review_title: 'JQuery/ Javascript Error on Slider in Wordpress theme', review_content: '"A true professional - he helped us out quickly with expertise and skill. Fantastic communication, which makes us certain that we will be working with WebGeniuz again and again! Thank you again - we look foward to working with you again very soon."', author: 'mmfnduk ',profile_img: 'profile_logo_4197929.jpg',review_rate: '5.0'},
      {ID: 205, review_title: 'Simple rating script', review_content: '"Mike did a great job on this project, and I look forward to working with him again."', author: 'Daymonh ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 206, review_title: 'Wordpress changes to existing web site', review_content: '"Did a great job on a technical project, would definitely hire again. [17 October, 2012] Did a great job on a technical project, would definitely hire again."', author: 'fletch88 ',profile_img: 'profile_logo_4296314.jpg',review_rate: '5.0'},
      {ID: 207, review_title: 'Project for Michael Only', review_content: '"Second project with Michael, certainly has some talent & hope we will be working on many more projects again in the future."', author: 'Dale R. ',profile_img: 'profile_logo_4014460.jpg',review_rate: '5.0'},
      {ID: 208, review_title: 'One page slicing', review_content: '"Great Freelancer, I am a web developer myself & can safely say this Freelancer provided great coding to a very high standard which is rare to find on here. Instantly took him on for my next project."', author: 'Dale R.',profile_img: 'profile_logo_4014460.jpg',review_rate: '5.0'},
      {ID: 209, review_title: 'Wordpress widget - "Locations near..."', review_content: '"Second project we worked together! Mike is fast and efficient."', author: 'nicktortike ',profile_img: 'profile_logo_1203546.jpg',review_rate: '5.0'},
      {ID: 210, review_title: 'LinkedIn member public profile code for wordpress', review_content: '"Mike is greate. Only need to say 2 words..... He Delivers!!! I will be working with him again for sure. Mike also goes above and beyond what is required if necessary and will always help out when possible. He is a perfertionist and always ready to give adice on solutions. Always makes things easier to have a developer that is passionate about his work. Will work with again."', author: 'shaneyin ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 211, review_title: 'Wordpress plugin - create searchable list of specific data stored in database', review_content: '"Quick, good communication, resourceful. Hiring him again for two other projects!"', author: 'nicktortike ',profile_img: 'profile_logo_1203546.jpg',review_rate: '5.0'},
      {ID: 212, review_title: 'Simple CSS change for Wordpress comments', review_content: '"Very fast and skilled programmer, and very patient since I myself was not able to respond on time sometimes during the fine-tuning. Kept modifying till I was satisfied, and also provides after-sales support. Perfect!"', author: 'medianl ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 213, review_title: 'Store Locator - EDITED', review_content: '"Was great to work with Mike on this project, he exceeded our expectations, and has plenty of time to talk the project through beforehand. We will definitely be using him again! Thanks again Mike!"', author: 'martyndoherty ',profile_img: 'profile_logo_4102718.jpg',review_rate: '5.0'},
      {ID: 214, review_title: 'Shipping Options Added To Wordpress Theme', review_content: '"Micheal did an amazing job add exceeded my expectations in every way. A very talented coder and web developer. The mod to my wordpress admin features came out great and as discussed. Understood and executed professionally and in a timely manner. Excellent and friendly communication. Will be using again in the future very soon."', author: 'Eric B.',profile_img: 'profile_logo_3803019.jpg',review_rate: '5.0'},
      {ID: 215, review_title: 'changes to website', review_content: '"Michael is excellent! he just powers through the work and is continually adding value through offfering alternatives and expertise. Nothing is ever a problem for him and he often goes well beyond what was requested. He really aims to please."', author: 'ConnectRecruit ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 216, review_title: 'Web modifications - reposted', review_content: '"excellent!"', author: 'ConnectRecruit ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 217, review_title: 'Notorious B.I.G Graphic Design', review_content: '"Michael was very professional the entire time - he completed the project quickly and I never hesitated to have anything changed. It was perfect. Keep it up Michael and you will go far! You will be the first choice for my next project."', author: 'notoriouscrow ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 218, review_title: 'Updating HTML and file structure', review_content: '"The job was done well with great communication, and Mike speedily took care of some project extras that came up."', author: 'perfectweb1 ',profile_img: 'profile_logo_1775117.jpg',review_rate: '5.0'},
      {ID: 219, review_title: 'Special PSD to HTML Landing Page', review_content: '"Grat work! Thank you!"', author: 'luzba',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 220, review_title: 'Website design for our site.', review_content: '"Was very good to work with and went above and beyond to help us out! Would highly recommend!"', author: 'cforell ',profile_img: 'profile_logo_1619018.jpg',review_rate: '5.0'},
      {ID: 221, review_title: 'Design and create a web site for a Cell Phone Store', review_content: '"Mike was great to work with. He went above and beyond my expectations as a web designer and developer. He Provided a quality web site, at a very affordable price. He was also great to work with when I ran into some issues with my current hosting company. I would hire Mike again in a heartbeat. Thanks a ton!"', author: 'boostsav ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 222, review_title: 'Simple Website hosting on my server', review_content: '"WebGeniuz is a great dev. Very concise and well verse. Very patient with me and have great suggestion. Will definitely do business with again. Thanks!"', author: 'ckang008 ',profile_img: 'profile_logo_2886080.jpg',review_rate: '5.0'},
      {ID: 223, review_title: 'search & database', review_content: '"highly reccomended! Pleasure to work with. Very talented programmer"', author: 'parudy ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 224, review_title: 'Intergrate a Subscribtion service on online download store', review_content: '"Top Bloke!! An actual web Genius.. Will use again for sure"', author: 'jeremysylvester ',profile_img: 'profile_logo_2793881.jpg',review_rate: '5.0'},
      {ID: 225, review_title: 'PSD to CMS', review_content: '"Michael really is a Geniuz! Thanks very much for a great job. Will use again & again."', author: 'hayleyc3 ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 226, review_title: 'Add Features and Make Minor Modification to my Website', review_content: '"Great job. Go far and beyond than you expected. Highly recommended."', author: 'kramer1975 ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 227, review_title: 'website pond plant database build based on my excel layout', review_content: '"Mike did an excellent job. We could not be happier. The project was intense and he went above and beyond to bring it to the top. On time and on budget. Made every tweak in design we asked for very quickly. A+! Perfect."', author: 'netguy3333 ',profile_img: 'profile_logo_785521.jpg',review_rate: '5.0'},
      {ID: 228, review_title: 'Simple Web Email Form', review_content: '"Mike performed wonderfully, his efforts on my project far exceeded his quote, but he was more then happy to get the project finished. Will be using again."', author: 'fetchpet ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 229, review_title: 'Create a user generated content site', review_content: '"Created an incredible website in a much shorter time than I expected. I am looking forward to working with him again!"', author: 'Steven G.',profile_img: 'profile_logo_1802983.jpg',review_rate: '5.0'},
      {ID: 230, review_title: 'Wordpress e-Commerce website needed', review_content: '"A-M-A-Z-I-N-G! Mike & the team from WebGeniuz are absolute LEGENDS. If you are looking for courteous, professional and exceptionally great work then use these guys from WebGeniuz. They went the extra mile and beyond, making sure I was 150% happy with my website. I could not have wished for a better experience with developers on freelancer! Thanks WebGeniuz for the outstanding work. If you are lucky enough to get a bid from these guys, take it!"', author: 'djsmooth555 ',profile_img: 'profile_logo_2034467.jpg',review_rate: '5.0'},
      {ID: 231, review_title: 'Ecommerce Website', review_content: '"I have hired several freelancer to do work here and this, by far, has been my best experience. ALL of the work was done even a long time issue that several other developers were unable to fix. His communication was great throughout and I would highly recommend him to others ... I already have!! Thanks again Mike for a project well done."', author: 'Conor',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 232, review_title: 'Sales Page, Content Page, Mascot + Label', review_content: '"WebGeniuz is one of, if not the best provider i have ever worked with. All hours of the day they helped us and worked on the project. They will be my go-to team from now on and I would recommend them to anyone. Perfect job. No complaints at all."', author: 'ad2llc',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 233, review_title: 'Artist web gallery', review_content: '"Very open, honest and helpful. Responded to emails straight away and was always accommodating to my new ideas! Completed in plenty of time and the site is exactly what we wanted. Brutal!"', author: 'leonormes ',profile_img: 'unknown_img.png',review_rate: '5.0'},
      {ID: 234, review_title: 'Custom IM System', review_content: '"Perfect as always! :-)"', author: 'matrevci ',profile_img: 'profile_logo_1692282.jpg',review_rate: '5.0'},
      {ID: 235, review_title: 'Custom jQuery Calculator Needed', review_content: '"This firm is quickly becoming my provider of choice on Freelancer. Definitely recommend them as always."', author: 'matrevci ',profile_img: 'profile_logo_1692282.jpg',review_rate: '5.0'},
      {ID: 236, review_title: 'Custom Footer Image Design', review_content: '"Perfect as always! :-)"', author: 'matrevci ',profile_img: 'profile_logo_1692282.jpg',review_rate: '5.0'},
      {ID: 237, review_title: 'Custom Messaging System', review_content: '"After using this firm for several projects, I can HIGHLY recommend them 100%. They are extremely professional, fast, and excellent programmers. Work with them if you get a chance...you would not be disappointed."', author: 'matrevci ',profile_img: 'profile_logo_1692282.jpg',review_rate: '5.0'},
   

    ]
  }

}
