import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  public innerWidth: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;

  colpath: any;
  colclass: any;
  colin: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }
  constructor() { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    console.log('on load', this.innerWidth);
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }

}
