import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { RequestSampleService } from '../../services/request-sample.service';
import { MatStepper } from '@angular/material/stepper';
import { Router, Event as NavigationEvent, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-help-overlay',
  templateUrl: './help-overlay.component.html',
  styleUrls: ['./help-overlay.component.css']
})
export class HelpOverlayComponent implements OnInit {
  public search: any = '';
  locked: any[] = [];
  public innerWidth: any;
  fordesktop = false;
  formobile = false;
  displayClass: any;
  deviceClass: any;
  heretext = false;
  custclass: string;
  part1class: string;
  part3class: string;
  part2class: string;
  part4class: string;
  part5class: string;
  clearpartsection: any;
  part5class_new: string;
  part6class: string;
  part7class: string;
  part8class: string;
  free_sample: any;
  designs: any;
  project_types: any;
  buttonDisabled  = true;
  loading: boolean;
  username: any;
  isLinear = true;
  progress: any;
  project_title: any;
  files: any = [];
  values: any;
  designvalue: any;
  exist_web_n: boolean;
  @HostListener('window:resize', ['$event'])
  // @ViewChild('stepper' , { static: false } ) stepper: MatStepper;
  // @ViewChild( 'stepper', { static: false } ) private stepper: MatStepper;
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  constructor(private router: Router,private formBuilder: FormBuilder, public requestObject:RequestSampleService) {
    this.exist_web_n = true;
    
    this.free_sample = this.formBuilder.group({
                          action: ['free-sample', Validators.required] ,
                          design: ['', Validators.required] ,
                          project_name: ['', Validators.required],
                          project_type: ['', Validators.required],
                          have_website: ['Yes', Validators.required],
                          website_url: [''],
                          files: null,
                          file_names: '',
                          designer_note: ['', Validators.required],
                          name: ['', Validators.required],
                          email: ['', [Validators.required, Validators.email]]
                        });
    this.designs  = { 'logo': false, 'website_design': false, 'brochure': false, 'mobile_app': false};
    this.project_types  = { 'new_project': false, 'existing_project': false };
    this.buttonDisabled = true;
    this.loading = false;
    this.progress = '0%' ;
    this.project_title  = 'A new project, how exciting!';

  }

  keyPress ( data: any ) {
    this.values = data.target.value;
    console.log('Typed lenth',this.values.length);
    if(this.values.length > 0){
      this.heretext = true;
    } else {
      this.heretext = false;
    }

  }

  ngOnInit() {
    this.locked = [
      // tslint:disable-next-line:max-line-length
      {ID: 1, Blog: 'Page Speed', desc: 'Page speed can easily be defined as the time it takes to display the content on a specific web page.', AuthID: '1', FormName: 'Mike', WinHandle: 'Oak Way'},
      {ID: 2, Blog: 'Webeteer\'s Website Development Process', desc: 'Webeteer follows a very strict development procedure, a development procedure that has led...', AuthID: '1', FormName: 'Mike', WinHandle: 'Oak Way'},
      // tslint:disable-next-line:max-line-length
  ];
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
    this.part1class = 'activepart';
    this.part2class = 'deactivepart';
    this.part3class = 'deactivepart';
    this.part4class = 'deactivepart';
    this.part5class = 'deactivepart';
    this.part5class_new = 'deactivepart';
    this.part6class ='deactivepart';
    this.part7class = 'deactivepart';
    this.part8class = 'deactivepart';

    this.free_sample.get('have_website')
        .valueChanges
        .subscribe(value => {
        const website_url = this.free_sample.get('website_url');
        if ( value === 'Yes' ) {


                website_url.enable();
                website_url.setValidators([Validators.required]);

                if ( website_url.value === '' ) {

                  this.buttonDisabled = true;
                } else {
                  this.buttonDisabled = false;
                }

        }  else {
              website_url.disable();
              website_url.clearValidators();
              this.buttonDisabled = false;
        }


    });
    this.free_sample.get('website_url')
        .valueChanges
        .subscribe(value => {
        const have_website = this.free_sample.get('have_website').value;
        if ( have_website === 'Yes' ) {
                if ( value  ) {
                  this.buttonDisabled = false;
                } else {
                  this.buttonDisabled = true;
                }
        }  else {
              this.buttonDisabled = false;
        }


    });



  }

  clearpopup() {
    this.part1class = 'deactivepart';
    this.part2class = 'deactivepart';
    this.part3class = 'deactivepart';
    this.part4class = 'deactivepart';
    this.part5class = 'deactivepart';
    this.part6class = 'deactivepart';
    this.part7class = 'deactivepart';
    this.part8class = 'deactivepart';
  }
  nextpart(partnum: any) {
    console.log(partnum)
    this.clearpopup();
    // this.clearpartsection.addListener('dragend', this.clearpopup());
     console.log("function called");
    if(partnum == 1){
      this.part1class = 'activepart';
    }
    if(partnum == 2)
    this.part2class = 'activepart';
    if(partnum == 3)
    this.part3class = 'activepart';
    if(partnum == 4)
    this.part4class = 'activepart';
    if(partnum == 5)
    this.part5class ='activepart';
    if(partnum == 6)
    this.part6class = 'activepart';
    if(partnum == 7)
    this.part7class = 'activepart';
    if(partnum == 8)
    this.part8class = 'activepart';
    console.log("finalpage");
  }

  nextProgress(val) {
    this.progress = 0;
    if ( val > 0 ) {
      this.progress = (val * (100 / 7)) + '%';
    }
  }
  resetForm(stepper) {

    stepper.reset();
    this.progress = '0%' ;
    this.free_sample.patchValue({
      action: 'free-sample',
      design: '',
      project_name: '',
      project_type: '',
      have_website: 'Yes',
      website_url: '',
      files: null,
      file_names: '',
      designer_note: '',
      name: '',
      email: '',
    });
    this.setDesign('', '');
    this.setProject('', '');
    this.files  = [];
    // this.nextpart(1);
  }

  freeSampleSubmit(stepper) {

    if  ( !this.free_sample.invalid ) {

      this.loading  = true;
      this.requestObject.sendFreeSample(this.free_sample.value).subscribe(request=>{
          this.username = this.free_sample.get('name').value;
          // stepper.next();
          this.loading  = false;
          this.setDesign('', '');
          this.setProject('', '');
          document.getElementById('btn-popup-close').click();
          this.router.navigate(['/thank-you']);
      });
      
    } else {

      const invalid = [];
        const controls = this.free_sample.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log('INVALID>>>>>>>>>', invalid);
    }

  }

  setDesign( val, key ) {
    this.free_sample.patchValue({
      design: val,
    });
    console.log(this.free_sample);
    this.designs.logo = false;
    this.designs.website_design = false;
    this.designs.brochure = false;
    this.designs.mobile_app = false;
    if ( key ) {
      this.designs[key]  = true;
    }

    this.designvalue = val;
  }
  setProject( val, key ) {
    this.free_sample.patchValue({
      project_type: val,
    });
    this.project_types.new_project = false;
    this.project_types.existing_project = false;
    if ( key ) {
      this.project_types[key]  = true;

      this.project_title  = (key === 'new_project') ?
      'A new project, how exciting!' : 'Looking at expanding an existing project, how neat!';
    }
  }


  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.free_sample.patchValue({
        files: file,
      });

    }
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const file = event[index];
      const formData = new FormData();
      formData.append('action', 'uploadfile');
      formData.append('file', file);

      let key = this.files.length;
      this.files.push(0);
      this.requestObject.saveFiles(formData).subscribe(request => {
          this.files[key] = request.name;
          const str_file  = (this.files.join(','));
          this.free_sample.patchValue({
            file_names: str_file,
          });
      });
    }
  }


  deleteAttachment(index) {

    const formData = new FormData();
    formData.append('action', 'deletefile');
    formData.append('file', this.files[index]);
    this.requestObject.deleteFiles(formData).subscribe(request => {
        this.files.splice(index, 1);
        const str_file  = (this.files.join(','));
        this.free_sample.patchValue({
          file_names: str_file,
        });
    });

  }
  check_website(val){

    if(val == 'no')
      this.exist_web_n = false;
    else
    this.exist_web_n = true;
  
}

}
