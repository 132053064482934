import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule }   from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import "froala-editor/js/froala_editor.pkgd.min.js";
import { BlogComponent } from './components/blog/blog.component';
import { BlogEditComponent } from './components/blog-edit/blog-edit.component';
import { DataTablesModule } from 'angular-datatables';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioEditComponent } from './components/portfolio-edit/portfolio-edit.component';
import { UsersComponent } from './components/users/users.component';
import { ForgotComponent } from './components/forgot/forgot.component';


export const routes: Routes = [
  {
    path: 'admin',
    component: LoginComponent,
    
    },
    {
      path: 'admin/dashboard',
      component: DashboardComponent,
      
      },
      {
        path: 'admin/privacy',
        component: PrivacyComponent,
        
      },
      {
        path: 'admin/blog',
        component: BlogComponent,
        
      },
      {
        path: 'admin/blog-edit',
        component: BlogEditComponent,
        
      },
      {
        path: 'admin/portfolio',
        component: PortfolioComponent,
        
      },
      {
        path: 'admin/portfolio-edit',
        component: PortfolioEditComponent,
        
      },
      {
        path: 'admin/users',
        component: UsersComponent,
        
      },
      {
        path: 'admin/forgot',
        component: ForgotComponent,
        
      }
  ];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    DataTablesModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [LoginComponent, DashboardComponent, SidebarComponent, HeaderComponent, PrivacyComponent, BlogComponent, BlogEditComponent, PortfolioComponent, PortfolioEditComponent, UsersComponent, ForgotComponent]
})
export class AdminModule { }
