import { Component, OnInit, HostListener } from '@angular/core';
import * as AOS from 'aos';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service'
@Component({
  selector: 'app-landing-plumber',
  templateUrl: './landing-plumber.component.html',
  styleUrls: ['./landing-plumber.component.css']
})
export class LandingPlumberComponent implements OnInit {
  public innerWidth: any;
  displayClass: any;
  deviceClass: any;
  tabClass: any;
  galleryClass: any;
  fordesktop = false;
  formobile = false;
  listdisplay = false;
  design  = true;
  integrate = false;
  logic = false;
  mobile  = false;
  launch  = false;
  public active:boolean = false;
  public buttonName: any = 'active';

  displayText = 'DESIGN';
  top_compliment_land: boolean;
  slug: string;
  splitted: string[];
  cityname: string;
  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.galleryClass = 'cust-col-5';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.galleryClass = 'col-md-12 marimg';
    }
  }
  constructor(private router: Router , private GlobalService:GlobalService) { 
    this.top_compliment_land = true;
  }

  home_portfolio  = [
    {
      'name': 'Smartengage',
      'desc': 'Engagement Automation Is The Future of Marketing',
      'imge': 'smartengage.png',
      'link': 'https://smartengage.com/',
      'show_order':'2'
     },
     {
      'name': 'Gunnar',
      'desc': 'Blue Light Glasses',
      'imge': 'gunnar.png',
      'link': 'https://gunnar.com/',
      'show_order':'1'
     },
     {
      'name': 'Furrion',
      'desc': 'Reinventing luxury living',
      'imge': 'furion.png',
      'link': 'https://www.furrion.com/',
      'show_order':'3'
     },
     {
      'name': 'Sezzle',
      'desc': 'Shop Now. Pay Later. No Interest. No Fees if paid on time. No Hard Credit Check. 4 payments over 6 weeks.',
      'imge': 'sezzle.png',
      'link': 'https://sezzle.com/',
      'show_order':'4'
     },
     {
      'name': 'Bella Vista',
      'desc': 'Beautiful Living',
      'imge': 'bellavista.png',
      'link': 'http://buildbellavista.com/',
      'show_order':'5'
     },
     {
      'name': 'Bacchusx',
      'desc': 'Companionship',
      'imge': 'bacchusx.png',
      'link': 'http://bacchusx.com/',
      'show_order':'6'
     },
     {
      'name': 'CNY Fertility Center',
      'desc': 'Affordable Quality Care',
      'imge': 'cny.png',
      'link': 'https://www.cnyfertility.com/',
      'show_order':'7'
     },
     {
      'name': 'Evolve GT',
      'desc': 'Motorcycle Track Day | Training',
      'imge': 'evolvegt.png',
      'link': 'https://evolvegt.com/',
      'show_order':'8'
     },
     {
      'name': 'Yaplat Casting',
      'desc': 'Free global casting website for actors, models & voiceover artists',
      'imge': 'yaplat.png',
      'link': 'https://www.yaplat.com/',
      'show_order':'9'
     },
     {
      'name': 'Skin Ingredients',
      'desc': 'welcome to the sk.in revolution.',
      'imge': 'skincare.png',
      'link': 'https://skiningredients.com/',
      'show_order':'10'
     },
     {
      'name': 'TranEx Group',
      'desc': 'Innovative traffic products, road signage, renewable energy and guardrails',
      'imge': 'tranex.png',
      'link': 'https://tranexgroup.com.au/',
      'show_order':'11'
     },
     {
      'name': 'Yumeoshop',
      'desc': 'Essential Dead Sea Treatment for Aqua Therapy',
      'imge': 'youmeshop.png',
      'link': 'https://yumeoshop.com/',
      'show_order':'12'
     },
     {
      'name': 'CanadaNailSupplies',
      'desc': 'Canada Nail Supply',
      'imge': 'cns.png',
      'link': 'http://canadanailsupplies.com',
      'show_order':'13'
     },
     {
      'name': 'Kwik-Look',
      'desc': 'kwik-look',
      'imge': 'kwik8.png',
      'link': 'https://kwik-look.com',
      'show_order': '14'
     },
     {
      'name': 'Boonzie',
      'desc': '',
      'imge': 'boonzie1.png',
      'link': '',
      'show_order': '15'
     },
     {
      'name': 'Chfa',
      'desc': '',
      'imge': 'chfa2.png',
      'link': '',
      'show_order': '16'
     },
     {
      'name': 'Consumer Debit',
      'desc': '',
      'imge': 'consumer-debit3.png',
      'link': '',
      'show_order': '17'
     },
     {
      'name': 'Doctor Kiltz',
      'desc': '',
      'imge': 'drkiltz4.png',
      'link': '',
      'show_order': '18'
     },
     {
      'name': 'Ez Pill',
      'desc': '',
      'imge': 'ez-pill5.png',
      'link': '',
      'show_order': '19'
     },
     {
      'name': 'Fhy',
      'desc': '',
      'imge': 'fhy6.png',
      'link': '',
      'show_order': '20'
     },
     {
      'name': 'Flower Addict',
      'desc': '',
      'imge': 'flower7.png',
      'link': '',
      'show_order': '21'
     },
     {
      'name': 'Moony Marketing Group',
      'desc': '',
      'imge': 'mmg9.png',
      'link': '',
      'show_order': '22'
     },
     {
      'name': 'Next Gen',
      'desc': '',
      'imge': 'next-gen10.png',
      'link': '',
      'show_order': '23'
     },
     {
      'name': 'NVHC',
      'desc': '',
      'imge': 'nvhc11.png',
      'link': '',
      'show_order': '24'
     },
     {
      'name': 'Ohara',
      'desc': '',
      'imge': 'ohara12.png',
      'link': '',
      'show_order': '25'
     },
     {
      'name': 'Polka',
      'desc': '',
      'imge': 'polka13.png',
      'link': '',
      'show_order': '26'
     },
     {
      'name': 'Cny Preserve',
      'desc': '',
      'imge': 'preserve14.png',
      'link': '',
      'show_order': '27'
     },
     {
      'name': 'Remax',
      'desc': '',
      'imge': 'remax15.png',
      'link': '',
      'show_order': '28'
     },
     {
      'name': 'Success',
      'desc': '',
      'imge': 'success16.png',
      'link': '',
      'show_order': '29'
     },
     {
      'name': 'The Debriefing Academy',
      'desc': '',
      'imge': 'tda17.png',
      'link': '',
      'show_order': '30'
     }
  ];
  

  google_reviews = [
    {
      'avatarimg': 'unknown_img.png',
      'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
      'reviewby': 'Angie T',
      'reviewdesc': 'Mike and his team are truly amazing at what they do. I have really enjoyed working with Mike; his level of care and high standards impressed me throughout our entire project. I am so impressed with what they have accomplished and will always go to them for all of our website needs. If you are looking for someone, look no further.  I can not fully express how invaluable he is! A Million Gold Stars!'
     },
     {
      'avatarimg': 'unknown_img.png',
      'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
      'reviewby': 'Richard Lehun',
      'reviewdesc': 'After many frustrating collaborations with web masters we were fortunate enough to meet Michael at Webeteer. Michael and his team have produced brilliantly and meticulously executed work for us. Creativity, attention to detail, and perfect functionality have been their trademarks. I can without reservation recommend Webeteer to any business needing a website or a webmaster. Because of the number of projects that Webeteer is handling, I recommend budgeting a little more time for the work, but the result is worth it.'
     },
    {
     'avatarimg': 'unknown_img.png',
     'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
     'reviewby': 'Matt Kaye',
     'reviewdesc': 'Mike and his team are truly stellar at what they do -- dedicated to completing the assignment through as many instances as required, bringing a degree of commitment and understanding of the project goals all too rare in this space. All at a highly competitive cost. We are in New York and trust me, it is well worth contracting this firm in Ontario. Note also their response time is unbeatable.'
    },
    {
      'avatarimg': 'unknown_img.png',
      'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
      'reviewby': 'william kiltz',
      'reviewdesc': 'Beautiful new site and the best communication I\'ve received in my professional career. Mike and the rest of hi team at Webeteer are real champs. Top this off with their affordability and there is no reason to go elsewhere.  Despite being down here in the states, it felt like there was no distance at all with their stellar communication. Hats off!'
    },
    {
      'avatarimg': 'unknown_img.png',
      'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
      'reviewby': 'Chris Bourgeois',
      'reviewdesc': 'I\'ve worked with Mike from Webeteer more than a couple times. He delivers work super fast and is a pleasure to work with.  He helped us revamp our blog and took my style I was going for and knocked it out of the park with the design, couldn\'t be happier with it.  Also, he\'s quite possibly the fastest typer I know, so with the coding I can only imagine how efficient he is!  If you\'re looking for quality web design, look no further. Webeteer is the best. \n Cheers Mike!'
    },
    {
      'avatarimg': 'unknown_img.png',
      'link': 'https://www.google.ca/search?q=webeteer&rlz=1C1CHBF_enCA809CA809&oq=webeteer&aqs=chrome..69i57j69i60l5.952j0j4&sourceid=chrome&ie=UTF-8#lrd=0x4d2a71ed4ddfc64d:0x6f0aac8745adf8f4,1',
      'reviewby': 'Damon King',
      'reviewdesc': 'We have used Webeteer numerous times over the years for what always seems to be an utter emergency and every single time no matter the day or time of day they have been there. They have seen us through many technically difficult situations with patience and professionalism every single time. No matter how small or large the projects they just get it done and with excellent communication to top it off. \n I can\'t recommend Webeteer enough, with them you are always in good hands. Thank you again!!'
    }
  ];

  ngOnInit() {
    this.GlobalService.setTopLandcompliment(this.top_compliment_land);
    AOS.init();
    this.innerWidth = window.innerWidth;
    console.log('on load kk', this.innerWidth);
    if (this.innerWidth >= 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.galleryClass = 'cust-col-5';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.galleryClass = 'col-md-12 marimg';
    }
    if (this.router.url) {
      this.slug = this.router.url.substr(1);
       this.splitted = this.slug.split('-', -1);
       this.cityname = this.splitted.pop();
    }

  }


  sortPortfolio(arr){
    const portt = [];
    for(const res of arr) {
      portt[ (parseInt(res.show_order)) - 1] = res;
    }
    return portt;
    
  }
  closecompliment(){
    // console.log("clikc");
    this.top_compliment_land = false;
    this.GlobalService.setTopLandcompliment(this.top_compliment_land);
    }
    scroll(el: HTMLElement) {
      el.scrollIntoView({behavior: 'smooth'});
    }

    

}
