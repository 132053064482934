import { Injectable } from '@angular/core';
import { Http, Response, Headers } from "@angular/http";
// import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RegisterServiceService {

  constructor(private http: Http) { }

  register(cname,cemail,phone,company_name,country,url,email_notification_progress,email_notification_completed,email_notification_comfeed){
    var headers= new Headers();
    headers.append('Content-Type','application/X-www-form=urlencoded');
    
    // return this.http.post("http://localhost/webeteer/api/user-register.php",{"cname":cname,"cemail":cemail,"phone":phone,"company_name":company_name,"country":country,"url":url,"email_notification_progress":email_notification_progress,"email_notification_completed":email_notification_completed,"email_notification_comfeed":email_notification_comfeed}).pipe(map(res=>res.json()));
    
    }
    
}
