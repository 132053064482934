import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { FacebookModule } from 'ngx-facebook';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { AngularFireModule } from 'angularfire2';
import { CountdownModule, Config } from 'ngx-countdown';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatStepperModule, MatInputModule, MatButtonModule, MatRadioModule} from '@angular/material';


// https://medium.com/codingthesmartway-com-blog/angular-2-firebase-introduction-b4f32e844db2

// New imports to update based on AngularFire2 version 4
// import { AngularFireDatabaseModule } from 'angularfire2/database-deprecated';
// import { AngularFireAuthModule } from 'angularfire2/auth';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { NoNavLayoutComponent } from './layout/no-nav-layout/no-nav-layout.component';
import { HeaderNavigationComponent } from './components/header/header-navigation/header-navigation.component';
import { HeaderTopNavigationComponent } from './components/header/header-top-navigation/header-top-navigation.component';
import { FooterMainComponent } from './components/footer/footer-main/footer-main.component';
import { BillingComponent } from './components/billing/billing.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogReadComponent } from './components/blog-read/blog-read.component';
import { LoginComponent } from './components/login/login.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { PlansAndPricingComponent } from './components/plans-and-pricing/plans-and-pricing.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioGalleryComponent } from './components/portfolio-gallery/portfolio-gallery.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { QuickStartComponent } from './components/quick-start/quick-start.component';
import { RegisterComponent } from './components/register/register.component';
import { RequestSampleComponent } from './components/request-sample/request-sample.component';
import { UserLoginMySubscriptionComponent } from './components/user-login-my-subscription/user-login-my-subscription.component';

import { RegisterServiceService } from './services/register-service.service';
import { RequestSampleService } from './services/request-sample.service';
import { HelpOverlayComponent } from './components/help-overlay/help-overlay.component';

import { AdminModule } from './components/admin/admin.module';
import { LoginService } from './services/admin/login.service';
// import { LoginGuard } from './components/admin/login.guard';
// import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AboutComponent } from './components/about/about.component';
import { PortfolioLayoutComponent } from './layout/portfolio-layout/portfolio-layout.component';
import { CompanyWebsiteComponent } from './components/company-website/company-website.component';
import { EcommerceWebsiteComponent } from './components/ecommerce-website/ecommerce-website.component';
import { CustomWebsiteComponent } from './components/custom-website/custom-website.component';
import { MobileWebsiteComponent } from './components/mobile-website/mobile-website.component';
import { CmsComponent } from './components/cms/cms.component';
import { ContentPageLayoutComponent } from './components/content-page-layout/content-page-layout.component';
import { BusinessCardsComponent } from './components/business-cards/business-cards.component';
import { MarketingMaterialComponent } from './components/marketing-material/marketing-material.component';
import { InvitationStationaryComponent } from './components/invitation-stationary/invitation-stationary.component';
import { DigitalMarketingComponent } from './components/digital-marketing/digital-marketing.component';
import { SignsPostersComponent } from './components/signs-posters/signs-posters.component';
import { SocialMediaOptimizationComponent } from './components/social-media-optimization/social-media-optimization.component';
import { BuildBlogComponent } from './components/build-blog/build-blog.component';
import { LeadGenerationComponent } from './components/lead-generation/lead-generation.component';
import { IphoneApplicationComponent } from './components/iphone-application/iphone-application.component';
import { AndroidApplicationComponent } from './components/android-application/android-application.component';
import { FacebookApplicationComponent } from './components/facebook-application/facebook-application.component';
import { HybridApplicationComponent } from './components/hybrid-application/hybrid-application.component';
import { WebsiteApplicationComponent } from './components/website-application/website-application.component';
import { WhoWeAreComponent } from './components/who-we-are/who-we-are.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { CharitableContributionsComponent } from './components/charitable-contributions/charitable-contributions.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { HomeHeaderComponent } from './components/header/home-header/home-header.component';
import { ContactComponent } from './components/contact/contact.component';
import { environment } from '../environments/environment';
import { SearchPipe } from './components/search.pipe';
import { HeaderTabComponent } from './components/header/header-tab/header-tab.component';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DisqusModule } from "ngx-disqus";
import { ContactBoxComponent } from './components/common/contact-box/contact-box.component';
import { WebsiteDesignComponent } from './components/website-design/website-design.component';
import { MobileTabletWebsiteDesignComponent } from './components/mobile-tablet-website-design/mobile-tablet-website-design.component';
import { MiscWebsiteDevelopmentComponent } from './components/misc-website-development/misc-website-development.component';
import { MobileAppDesignComponent } from './components/mobile-app-design/mobile-app-design.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandComponent } from './components/land/land.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { NewportfolioComponent } from './newportfolio/newportfolio.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ThankyouLatestComponent } from './components/thankyou-latest/thankyou-latest.component';
import { LandingContractorComponent } from './components/landing-contractor/landing-contractor.component';
import { LandingPlumberComponent } from './components/landing-plumber/landing-plumber.component';
//import { NewlandComponent } from './components/newland/newland.component';


const appRoutes: Routes = [

  // Site routes goes here
  { path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full'}
    // { path: 'dashboard', component: DashboardComponent },
    // { path: 'profile', component: ProfileComponent }
    ]
  },
      { path: '',
          component: AppLayoutComponent,
          children: [
          { path: 'quick-start', component: QuickStartComponent },
          { path: 'privacy-policy', component: PrivacyPolicyComponent },
          { path: 'request-sample', component: RequestSampleComponent },
          { path: 'my-account', component: MyAccountComponent },
          { path: 'plans-and-pricing', component: PlansAndPricingComponent },
          { path: 'blog', component: BlogComponent },
          { path: 'blog-read/:slug', component: BlogReadComponent },
          { path: 'user-subscription', component: UserLoginMySubscriptionComponent },
          { path: 'billing', component: BillingComponent },
          { path: 'about', component: AboutComponent },
          { path: 'contact', component: ContactComponent },
          { path: 'portfolio-gallery', component: PortfolioGalleryComponent },
          { path: 'testimonials', component: TestimonialsComponent },
          { path: 'thankyou', component: ThankyouComponent },
          { path: 'newportfolio', component: NewportfolioComponent },
          {path : 'thank-you', component:ThankyouLatestComponent},
        //  {path : 'newland', component:NewlandComponent}
          ]
      },
      // Page routes goes here here
      { path: '',
          component: PageLayoutComponent,
          children: [
          { path: 'build-a-company-website', component: CompanyWebsiteComponent },
          { path: 'ecommerce-website-development', component: EcommerceWebsiteComponent },
          { path: 'custom-website-development', component: CustomWebsiteComponent },
          { path: 'content-management-system', component: CmsComponent },
          { path: 'mobile-tablet-website-development', component: MobileWebsiteComponent },
          { path: 'business-card', component: BusinessCardsComponent },
          { path: 'marketing-material', component: MarketingMaterialComponent },
          { path: 'invitation-stationary', component: InvitationStationaryComponent },
          { path: 'digital-marketing', component: DigitalMarketingComponent },
          { path: 'signs-posters', component: SignsPostersComponent },
          { path: 'social-media-optimization', component: SocialMediaOptimizationComponent },
          { path: 'build-blog', component: BuildBlogComponent },
          { path: 'lead-generation-tools', component: LeadGenerationComponent },
          { path: 'iphone-application', component: IphoneApplicationComponent },
          { path: 'android-application', component: AndroidApplicationComponent },
          { path: 'facebook-application', component: FacebookApplicationComponent },
          { path: 'hybrid-application', component: HybridApplicationComponent },
          { path: 'website-application', component: WebsiteApplicationComponent },
          { path: 'who-we-are', component: WhoWeAreComponent },
          // { path: 'testimonials', component: TestimonialsComponent },
          { path: 'charitable-contributions', component: CharitableContributionsComponent },
          { path: 'website-design', component: WebsiteDesignComponent },
          { path: 'mobile-and-tablet-website-design', component: MobileTabletWebsiteDesignComponent },
          { path: 'misc-website-design', component: MiscWebsiteDevelopmentComponent },
          { path: 'mobile-app-design', component: MobileAppDesignComponent }
          

           ]
      },
      // App routes goes here here
      { path: '',
        component: SiteLayoutComponent,
        children: [
          { path: 'help', component: HelpOverlayComponent },

        // { path: 'dashboard', component: DashboardComponent },
        // { path: 'profile', component: ProfileComponent }
        ]
      },
      { path: '',
        component: PortfolioLayoutComponent,
        children: [

        ]
      },
      // NO Nav routes goes here here
    { path: '',
      component: NoNavLayoutComponent,
      children: [
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'free-website-design-electrician-newmarket', component: LandComponent },
        { path: 'free-website-design-electrician-aurora', component: LandComponent },
        { path: 'free-website-design-electrician-keswick', component: LandComponent },
        { path: 'free-website-design-electrician-eastgwillimbury', component: LandComponent },
        { path: 'free-website-design-electrician-westgwillimbury', component: LandComponent },
        { path: 'free-website-design-electrician-stouffville', component: LandComponent },
        { path: 'free-website-design-electrician-barrie', component: LandComponent },
        { path: 'free-website-design-electrician-orillia', component: LandComponent },
        { path: 'free-website-design-electrician-bracebridge', component: LandComponent },
        { path: 'free-website-design-electrician-gravenhurst', component: LandComponent },
        { path: 'free-website-design-electrician-huntsville', component: LandComponent },

        { path: 'free-website-design-contractor-newmarket', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-aurora', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-keswick', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-eastgwillimbury', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-westgwillimbury', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-stouffville', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-barrie', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-orillia', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-bracebridge', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-gravenhurst', component: LandingContractorComponent },
        { path: 'free-website-design-contractor-huntsville', component: LandingContractorComponent },

        { path: 'free-website-design-plumber-newmarket', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-aurora', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-keswick', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-eastgwillimbury', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-westgwillimbury', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-stouffville', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-barrie', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-orillia', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-bracebridge', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-gravenhurst', component:  LandingPlumberComponent },
        { path: 'free-website-design-plumber-huntsville', component:  LandingPlumberComponent }
      ]
    },
    { path: 'admin',
          loadChildren: './components/admin/admin.module#AdminModule'
    },
    {
      path: ":slug",
      component: HomeLayoutComponent,
      children: [
        { path: '', component: HomeComponent,}
      ]
    },
    {
        path: "**",
        component: PagenotfoundComponent,

    }
  ];
 export const firebaseConfig = {
      apiKey: 'AIzaSyDeue2IfeZFxZ9UXr9QHAilsU07Q3gT6GM',
      authDomain: 'webeteer-c4f31.firebaseapp.com',
      databaseURL: 'https://webeteer-c4f31.firebaseio.com',
      projectId: 'webeteer-c4f31',
      storageBucket: 'webeteer-c4f31.appspot.com',
      messagingSenderId: '170821854922'
  };
  function countdownConfigFactory(): Config {
    return { template: `$!h!:$!m!:$!s!` };
  }
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    NoNavLayoutComponent,
    HeaderNavigationComponent,
    HeaderTopNavigationComponent,
    FooterMainComponent,
    BillingComponent,
    BlogComponent,
    BlogReadComponent,
    LoginComponent,
    MyAccountComponent,
    PlansAndPricingComponent,
    PortfolioComponent,
    PortfolioGalleryComponent,
    PrivacyPolicyComponent,
    QuickStartComponent,
    RegisterComponent,
    RequestSampleComponent,
    UserLoginMySubscriptionComponent,
    HelpOverlayComponent,
    AboutComponent,
    PortfolioLayoutComponent,
    CompanyWebsiteComponent,
    EcommerceWebsiteComponent,
    CustomWebsiteComponent,
    MobileWebsiteComponent,
    CmsComponent,
    ContentPageLayoutComponent,
    BusinessCardsComponent,
    MarketingMaterialComponent,
    InvitationStationaryComponent,
    DigitalMarketingComponent,
    SignsPostersComponent,
    SocialMediaOptimizationComponent,
    BuildBlogComponent,
    LeadGenerationComponent,
    IphoneApplicationComponent,
    AndroidApplicationComponent,
    FacebookApplicationComponent,
    HybridApplicationComponent,
    WebsiteApplicationComponent,
    WhoWeAreComponent,
    TestimonialsComponent,
    CharitableContributionsComponent,
    HomeLayoutComponent,
    HomeHeaderComponent,
    ContactComponent,
    SearchPipe,
    HeaderTabComponent,
    PageLayoutComponent,
    ContactBoxComponent,
    WebsiteDesignComponent,
    MobileTabletWebsiteDesignComponent,
    MiscWebsiteDevelopmentComponent,
    MobileAppDesignComponent,
    ThankyouComponent,

    LandComponent,

    DragDropDirective,

    NewportfolioComponent,
    PagenotfoundComponent,
    ThankyouLatestComponent,
    LandingContractorComponent,
    LandingPlumberComponent,
    // NewlandComponent
  
    // TestimonialsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    HttpModule,
    AdminModule,
    MatTooltipModule,
    FacebookModule.forRoot(),
    InfiniteScrollModule,
    SlickCarouselModule,
    SwiperModule,
    ReactiveFormsModule,
    DisqusModule.forRoot('ngx'),
    ModalModule.forRoot(),
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    DeferLoadModule,
    MatStepperModule, MatInputModule, MatButtonModule, MatRadioModule,
    CountdownModule
],
  providers: [
    RegisterServiceService, 
    LoginService,
    RequestSampleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
