import { Injectable } from '@angular/core';
import { Http, Response, Headers } from "@angular/http";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class RequestSampleService {
  url: any
  constructor(private http: Http) {
      this.url = 'https://dev.webeteer.com/api/public/';
  }

  sendRequest(form){
    var headers= new Headers();
    headers.append('Content-Type', 'application/X-www-form=urlencoded');
    return this.http.post(this.url + 'request-sample', form).pipe(map(res=>res.json()));
  }

  sendFreeSample(form){
    const formData  = this.toFormData(form);
    return this.http.post(this.url + 'free-sample', formData ).pipe(map(res=>res.json()));
  }
  saveFiles(form) {
    return this.http.post(this.url + 'upload-file', form ).pipe(map(res=>res.json()));
  }
  deleteFiles(form) {
    return this.http.post(this.url + 'delete-file', form ).pipe(map(res=>res.json()));
  }
  toFormData<T>( formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }
}
