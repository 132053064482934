import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submittedData: any;
  data: any;
  submitted = false;
  error_message = '';
  successBar: any;
  success_message: any;
  constructor(fb: FormBuilder, private requestObject: ContactService) {
    this.contactForm = fb.group({
      first_name : ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      last_name : ['', Validators.compose([Validators.required])],
      email : ['', Validators.compose([Validators.required, Validators.email])],
      phone : [''],
      message : ['']
    });
  }
  get FName() { return this.contactForm.get('first_name'); }
  get LName() { return this.contactForm.get('last_name'); }
  get Email() { return this.contactForm.get('email'); }
  private formSubmitAttempt: boolean;
  ngOnInit() {
  }
   contactsubmit(event) {
    this.formSubmitAttempt = true;
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.submittedData = this.contactForm.value;
     this.requestObject.contactsubmit(this.submittedData).subscribe( response => {
      this.successBar  = document.querySelector('.error_message');
      // tslint:disable-next-line:max-line-length
      this.success_message = '<h3>Your enquiry was submitted and will be responded to as soon as possible.</h3><p>Thank you for contacting us.</p>';
      this.error_message = this.success_message;
      this.contactForm.reset();
      this.formSubmitAttempt = false;
      });

  }
}
