import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-portfolio-gallery',
  templateUrl: './portfolio-gallery.component.html',
  styleUrls: ['./portfolio-gallery.component.css']
})
export class PortfolioGalleryComponent implements OnInit {
  public innerWidth: any;
  fordesktop = false;
  formobile = false;
  displayClass: any;
  deviceClass: any;
  loading = false;
  loadingvar: any;
  vislim: any;
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }

  array = [];
  sum = 10;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;
  visibleLimit  =   9;
  showLimit = 100;


  constructor() {
    //this.appendItems(0, this.sum);
   }

    // tslint:disable-next-line:member-ordering
    arrayy = [
    {
     'name': 'Chicago Real Estate Agents',
     'desc': 'Voted #1 Realtors in Illinois (Recommended)',
     'imge': 'chicago.jpg',
     'link': 'https://www.chicago-realestateagents.com/',
     'show_order':'72'
    },
    {
      'name': 'Dr. Derrick Chua, DMD',
      'desc': 'Dentist in San Francisco, CA',
      'imge': 'chuadmd.jpg',
      'link': 'https://chuadmd.com/',
      'show_order':'73'
     },
     {
      'name': 'D&R Interiors',
      'desc': 'Full Service Residential Drywall Company',
      'imge': 'D&R.jpg',
      'link': 'https://dandrinteriors.ca/',
      'show_order':'74'
     },
     {
      'name': 'R V Siding',
      'desc': 'Quality Craftsmanship with Every Product Produced',
      'imge': 'rvsiding.jpg',
      'link': 'http://rvsiding.com/',
      'show_order':'80'
     },
     {
      'name': 'Dolly Varden',
      'desc': 'Outdoor Clothing',
      'imge': 'dollyvardenoc.jpg',
      'link': 'https://dollyvardenoc.com/',
      'show_order':'85'
     },
     {
      'name': 'Tablecloth',
      'desc': 'Measure. Amplify. Matter.',
      'imge': 'table cloth.jpg',
      'link': 'https://www.tablecloth.io/',
      'show_order':'29'
     },
     {
      'name': 'Sezzle',
      'desc': 'Shop Now. Pay Later. No Interest. No Fees if paid on time. No Hard Credit Check. 4 payments over 6 weeks.',
      'imge': 'sezzle.jpg',
      'link': 'https://sezzle.com/',
      'show_order':'4'
     },
     {
      'name': 'Bella Vista',
      'desc': 'Beautiful Living',
      'imge': 'bellavista.jpg',
      'link': 'http://buildbellavista.com/',
      'show_order':'5'
     },
     {
      'name': '2112HockeyAgency',
      'desc': 'Hockey Agency Canada',
      'imge': '2112.jpg',
      'link': 'http://2112hockeyagency.com/',
      'show_order':'18'
     },
     {
      'name': 'CNY Fertility Center',
      'desc': 'Affordable Quality Care',
      'imge': 'cny.jpg',
      'link': 'https://www.cnyfertility.com/',
      'show_order':'7'
     },
     {
      'name': 'Alden Grace',
      'desc': 'Personalized Line Stationary',
      'imge': 'alden.jpg',
      'link': 'https://www.aldengrace.com/',
      'show_order':'15'
     },
     {
      'name': 'Pacific Real Estate Innovations',
      'desc': 'Pacific Real Estate Innovations',
      'imge': 'pacific.jpg',
      'link': 'http://pacificrealestateinnovations.com/',
      'show_order':'19'
     },
     {
      'name': 'Icrme',
      'desc': 'Revenue Management Training Certification',
      'imge': 'icrme.jpg',
      'link': 'http://icrm.education/',
      'show_order':'42'
     },
     {
      'name': 'Next Gen Sports Academy',
      'desc': 'Next Gen Sports Academy',
      'imge': 'next gen.jpg',
      'link': 'https://nextgensa.com.au/',
      'show_order':'20'
     },
     {
      'name': 'Smartengage',
      'desc': 'Engagement Automation Is The Future of Marketing',
      'imge': 'smartengage.jpg',
      'link': 'https://smartengage.com/',
      'show_order':'1'
     },
     {
      'name': 'Wise Owl Financial Advisors',
      'desc': 'Perth &amp; Adelaide Financial Planning',
      'imge': 'wise owl.jpg',
      'link': 'http://wiseowlfinancial.com.au/',
      'show_order':'21'
     },
     {
      'name': 'Furrion',
      'desc': 'Reinventing luxury living',
      'imge': 'furrion.jpg',
      'link': 'https://www.furrion.com/',
      'show_order':'3'
     },
     {
      'name': 'TAN@HOME',
      'desc': 'Singapore Spray Tan | Competition Tan',
      'imge': 'Tanathome.jpg',
      'link': 'https://tanathome.com.sg/',
      'show_order':'23'
     },
     {
      'name': 'RoadWages',
      'desc': 'Local door to door delivery for your small parcels',
      'imge': 'Roadwages.jpg',
      'link': 'http://roadwages.com/',
      'show_order':'86'
     },
     {
      'name': 'StageWages',
      'desc': 'Live Local Gigs/Great Musical Talent',
      'imge': 'Stagewages.jpg',
      'link': 'http://stagewages.com/',
      'show_order':'24'
     },
     {
      'name': 'Pink Stork.',
      'desc': 'Experts in Prenatal  Nutrition',
      'imge': 'Pink stork.jpg',
      'link': 'https://pinkstork.com/',
      'show_order':'25'
     },
     {
      'name': 'Polka Dot Paper',
      'desc': 'Wedding Invitation Stationery Melbourne',
      'imge': 'Polka dot paper.jpg',
      'link': 'https://www.polkadotpaper.com/',
      'show_order':'26'
     },
     {
      'name': 'Skin Ingredients',
      'desc': 'welcome to the sk.in revolution.',
      'imge': 'skiningrediants.png',
      'link': 'https://skiningredients.com/',
      'show_order':'27'
     },
     {
      'name': 'Nvhcreston',
      'desc': 'Northern Virginia Hebrew Congregation',
      'imge': 'Nvhc.jpg',
      'link': 'http://www.nvhcreston.org/',
      'show_order':'30'
     },
     {
      'name': 'KidSono',
      'desc': 'Pediatric Point-of-Care Ultrasound',
      'imge': 'kidsono.jpg',
      'link': 'https://kidsono.com/',
      'show_order':'81'
     },
     {
      'name': 'Simplified Networks',
      'desc': 'Reliable, Affordable Business VoIP Telephone Service',
      'imge': 'Simplified networks.jpg',
      'link': 'https://simplifiednetworks.net/',
      'show_order':'31'
     },
     {
      'name': 'MooreHouse',
      'desc': 'Training Academy',
      'imge': 'Moorehouse.jpg',
      'link': 'https://moorehouseacademy.co.uk/',
      'show_order':'32'
     },
     {
      'name': 'Interfire Systems',
      'desc': 'Providing Fire Systems with Intelligence',
      'imge': 'Interfire.jpg',
      'link': 'http://interfiresystems.com/',
      'show_order':'33'
     },
     {
      'name': 'ELOS',
      'desc': 'The Aquarium Company',
      'imge': 'ELOS.jpg',
      'link': 'http://www.elosamerica.com/',
      'show_order':'87'
     },
     {
      'name': 'Health Wellness Bazaar',
      'desc': 'Affordable and High Quality Medical Treatment',
      'imge': 'hwbazaar.jpg',
      'link': 'https://hwbazaar.com/',
      'show_order':'35'
     },
     {
      'name': 'ECOLE YOGA DU RIRE',
      'desc': 'Ecole du yoga du rire, clubs de rire, mieux-être, santé mentale, gestion du stress, cohésion d&#039;équipe',
      'imge': 'ECOLE DU YOGA DU RIRE.jpg',
      'link': 'https://www.yogadurire.com/',
      'show_order':'36'
     },
     {
      'name': 'Entrepath Online',
      'desc': 'Duis vel nibh at velit',
      'imge': 'Entrypath.jpg',
      'link': 'http://entrepathonline.com/',
      'show_order':'37'
     },
     {
      'name': 'FATTYCRABS',
      'desc': 'PORK FREE &amp; ALCOHOL FREE',
      'imge': 'fattycrabs.jpg',
      'link': 'https://www.fattycrabs.com/',
      'show_order':'38'
     },
     {
      'name': 'Evolve GT',
      'desc': 'Motorcycle Track Day | Training',
      'imge': 'Evolve Gt.jpg',
      'link': 'https://evolvegt.com/',
      'show_order':'8'
     },
     {
      'name': 'Dentalvana',
      'desc': 'Family Dentist Near Me | Modern Family Dentistry',
      'imge': 'dental vana.jpg',
      'link': 'http://dentalvana.com/',
      'show_order':'40'
     },
     {
      'name': 'COMIVO',
      'desc': 'global chemical marketplace',
      'imge': 'comivo.jpg',
      'link': 'https://comivo.com/',
      'show_order':'22'
     },
     {
      'name': 'Blade Recreation',
      'desc': 'Innovation in Recreation',
      'imge': 'blade recreation.jpg',
      'link': 'https://bladerecreation.com.au/',
      'show_order':'41'
     },
     {
      'name': 'Foundation Technology',
      'desc': 'Foundation Technology',
      'imge': 'foundation.jpg',
      'link': '#',
      'show_order':'39'
     },
    {
      'name': 'Bacchusx',
      'desc': 'Companionship',
      'imge': 'Bacchusx.jpg',
      'link': 'http://bacchusx.com/',
      'show_order':'6'
     },
     {
      'name': '204 AUCTIONS',
      'desc': 'Online Bidding',
      'imge': '204auctions.jpg',
      'link': 'http://204auctions.com/',
      'show_order':'43'
     },
     {
      'name': 'BDNMB',
      'desc': 'bdnmb.ca Brandon MB',
      'imge': 'Bdnmb.jpg',
      'link': 'https://bdnmb.ca/',
      'show_order':'44'
     },
     {
      'name': 'Gunnar',
      'desc': 'Blue Light Glasses',
      'imge': 'img1.jpg',
      'link': 'https://gunnar.com/',
      'show_order':'2'
     },
     {
      'name': 'Business Buying Accelerator',
      'desc': 'with Carl Allen',
      'imge': 'businessbuyingaccelerator.jpg',
      'link': 'https://businessbuyingaccelerator.com/register/',
      'show_order':'45'
     },
     {
      'name': 'GN TV Website & Application',
      'desc': 'Duis vel nibh at velit',
      'imge': 'GNTV.jpg',
      'link': 'https://gntvuk.com',
      'show_order':'10'
     },
     {
      'name': 'Brandonpaasch',
      'desc': 'KTM Cup Champion',
      'imge': 'BrandonPaschh.jpg',
      'link': 'https://brandonpaasch.com/',
      'show_order':'46'
     },
     {
      'name': 'Entrepath Online',
      'desc': 'Entrepath Online',
      'imge': 'Entrepath11.jpg',
      'link': 'http://entrepathonline.com/',
      'show_order':'47'
     },
     {
      'name': 'Graphic Design Services in Montreal',
      'desc': 'Let’s Grow Your Brand',
      'imge': 'MontrealGraphicDesign1.jpg',
      'link': 'https://www.mtlgraphicdesign.com/',
      'show_order':'48'
     },
     {
      'name': 'Floweraddict',
      'desc': 'Online Florist Singapore',
      'imge': 'Floweraddict.jpg',
      'link': 'https://floweraddict.com/',
      'show_order':'28'
     },
     {
      'name': 'Carleson Moore',
      'desc': 'Class Safety Comfort',
      'imge': 'carlessonmoore1.jpg',
      'link': 'http://carlesonmoore.com/',
      'show_order':'49'
     },
     {
      'name': 'CanadaNailSupplies',
      'desc': 'Canada Nail Supply',
      'imge': 'cns11.jpg',
      'link': 'http://canadanailsupplies.com',
      'show_order':'14'
     },
     {
      'name': 'JI Phone Repair',
      'desc': '',
      'imge': 'JIPhoneRepair.jpg',
      'link': 'https://jiphonerepair.com/',
      'show_order':'50'
     },
     {
      'name': 'Clutch Chairz Gaming Chairs',
      'desc': 'The Worlds Best Chair for Gamers',
      'imge': 'Clutch.jpg',
      'link': 'https://usa.clutchchairz.com/',
      'show_order':'51'
     },
     {
      'name': 'Dalmatian',
      'desc': 'Smart Fire Prevention',
      'imge': 'Dalmatian.jpg',
      'link': 'http://yourdalmatian.com/',
      'show_order':'52'
     },
     {
      'name': 'Stars Broking',
      'desc': 'Stars Broking Services',
      'imge': 'StarsBroking.jpg',
      'link': 'http://starsbroking.com.au/',
      'show_order':'53'
     },
     {
      'name': 'Canadian Head',
      'desc': 'Duis vel nibh at velit',
      'imge': 'canadianhead.jpg',
      'link': 'http://canadianhead.com/',
      'show_order':'54'
     },
     {
      'name': 'Busy Beaver Tree Services',
      'desc': 'Climbing Arborist',
      'imge': 'BusyBeaver.jpg',
      'link': 'https://busybeavertreeservices.ca/',
      'show_order':'55'
     },
     {
      'name': 'Ninja Acquisitions',
      'desc': 'Duis vel nibh at velit',
      'imge': 'Ninja1.jpg',
      'link': 'http://www.ninjaacquisitions.com/',
      'show_order':'56'
     },
     {
      'name': 'Yaplat Casting',
      'desc': 'Free global casting website for actors, models & voiceover artists',
      'imge': 'yapletcasting.jpg',
      'link': 'https://www.yaplat.com/',
      'show_order':'9'
     },
     {
      'name': 'Beyond Measure Design',
      'desc': 'Duis vel nibh at velit',
      'imge': 'beyond.jpg',
      'link': 'https://www.beyondmeasuredesign.ca/',
      'show_order':'57'
     },
     {
      'name': 'BrenTuning',
      'desc': 'Duis vel nibh at velit',
      'imge': 'brentuning1.jpg',
      'link': 'http://brentuning.com/',
      'show_order':'58'
     },
     {
      'name': 'On Point Sports Therapy',
      'desc': 'Sports and Remedial Massage in Fremantle',
      'imge': 'OnPoint.jpg',
      'link': 'http://onpointsportstherapy.com.au/',
      'show_order':'59'
     },
     {
      'name': 'GN Media',
      'desc': 'GN Media brings the latest news, etc.',
      'imge': 'GNMedia.jpg',
      'link': 'https://www.gnglobalmedia.com/',
      'show_order':'60'
     },
     {
      'name': 'Cabcomm',
      'desc': 'Cabbcomm Communications',
      'imge': 'Carbcomm.jpg',
      'link': 'http://www.cabbcomm.com/',
      'show_order':'61'
     },
     {
      'name': 'Atlanta Suzuki Institute',
      'desc': 'beautiful tone, beautiful heart.',
      'imge': 'atlanta.jpg',
      'link': 'http://www.atlantasuzuki.org/',
      'show_order':'62'
     },
     {
      'name': 'MotoGladiator',
      'desc': 'Motorcycle Track Day Provider North Carolina',
      'imge': 'MotoGladiator.jpg',
      'link': 'http://motogladiator.com/',
      'show_order':'63'
     },
     {
      'name': 'BrenTuning moto',
      'desc': 'More Than Just A Tuning Shop',
      'imge': 'brentuningmoto.jpg',
      'link': 'https://brentuningmoto.com/',
      'show_order':'63'
     },
     {
      'name': 'Am House',
      'desc': 'CONNECTING PEOPLE',
      'imge': 'AmHouse.jpg',
      'link': 'http://amhouseinc.com',
      'show_order':'64'
     },
     {
      'name': 'Luff Industries',
      'desc': 'Conveying Performance',
      'imge': 'luffindustries.jpg',
      'link': 'https://www.luffindustries.com/',
      'show_order':'11'
     },
     {
      'name': '3DBillet',
      'desc': 'Custom Billet Car Parts for Every Car Enthusiast!',
      'imge': '3Dbillet.jpg',
      'link': 'https://3dbillet.com/',
      'show_order':'88'
     },
     {
      'name': 'Fastvisa',
      'desc': 'Global immigration lawyers.',
      'imge': 'Fastvisa.jpg',
      'link': 'https://www.fastvisagroup.com/',
      'show_order':'65'
     },
     {
      'name': 'Amansan Television',
      'desc': 'ATV is the only channel that provides 100% Ghanaian local content.',
      'imge': 'ATV11.jpg',
      'link': 'https://www.atvgh.com/',
      'show_order':'66'
     },
     {
      'name': 'TranEx Group',
      'desc': 'Innovative traffic products, road signage, renewable energy and guardrails',
      'imge': 'Tranex.jpg',
      'link': 'https://tranexgroup.com.au/',
      'show_order':'12'
     },
     {
      'name': 'BajaOrtho',
      'desc': 'Baja Orthopaedic Institute',
      'imge': 'bajaortho.jpg',
      'link': 'http://bajaortho.com/',
      'show_order':'67'
     },
     {
      'name': 'Baseball In The Attic',
      'desc': 'Vintage Sports Cards & Memorabilia',
      'imge': 'baseball.jpg',
      'link': 'http://www.baseballintheattic.com/',
      'show_order':'68'
     },
     {
      'name': 'Yumeoshop',
      'desc': 'Essential Dead Sea Treatment for Aqua Therapy',
      'imge': 'yumeoshop1.jpg',
      'link': 'https://yumeoshop.com/',
      'show_order':'13'
     },
     {
      'name': 'Curexe',
      'desc': 'Scale Your Company',
      'imge': 'Curexe.jpg',
      'link': 'https://blog.curexe.com/',
      'show_order':'16'
     },
     {
      'name': 'Muslim Foster Care',
      'desc': 'Helping those in need!',
      'imge': 'MuslimFosterCare.jpg',
      'link': 'https://muslimfostercare.org/',
      'show_order':'89'
     },
     {
      'name': 'Mova Law Group',
      'desc': 'San Diego Personal Injury Lawyers',
      'imge': 'mova law group.jpg',
      'link': 'https://www.movalegal.com/',
      'show_order':'69'
     },
     {
      'name': 'Remax',
      'desc': 'Comerical Division',
      'imge': 'remax.jpg',
      'link': 'https://www.commercialyeg.ca/',
      'show_order':'70'
     },
     {
      'name': 'Blue Aspect Properties',
      'desc': 'Blue Aspect Properties',
      'imge': 'blue aspect.jpg',
      'link': 'http://blueaspectproperties.com/',
      'show_order':'71'
     },
     {
      'name': 'Orica',
      'desc': 'Building meaningful connections & movements',
      'imge': 'epicallianz.jpg',
      'link': 'http://epicallianz.com/',
      'show_order':'75'
     },
     {
      'name': 'Create By Faith',
      'desc': 'Moves Mountains',
      'imge': 'Create By Faith.jpg',
      'link': 'https://cbf.agency/',
      'show_order':'76'
     },
     {
      'name': 'Meraki Staging',
      'desc': 'Create love at first sight',
      'imge': 'meraki.jpg',
      'link': 'https://merakistaging.ca/',
      'show_order':'77'
     },
     {
      'name': 'Gn Media Simple Site',
      'desc': 'International Business Solutions LLC',
      'imge': 'internationalbusinesssolutionsllc.jpg',
      'link': 'https://internationalbusinesssolutionsllc.com/',
      'show_order':'78'
     },
     {
      'name': 'Josh Allen',
      'desc': 'Josh Allen',
      'imge': 'joshallen.jpg',
      'link': '#',
      'show_order':'79'
     },
     {
      'name': 'Tri-State Basement Repair',
      'desc': 'Ready to solve your basement problems?',
      'imge': 'internationalbusinesssolutionsllc.jpg',
      'link': 'http://tristatebasementrepair.com/',
      'show_order':'17'
     },
     {
      'name': 'Vesuvius Grill Co',
      'desc': 'Ovens for Internal and Garden Use',
      'imge': 'versious.jpg',
      'link': 'https://vesuviusgrillco.com/',
      'show_order':'34'
     },
     {
      'name': 'Bright Leaf Properties',
      'desc': 'Direct Real Estate Investing',
      'imge': 'brightleaf.jpg',
      'link': 'http://brightleafproperties.com/',
      'show_order':'82'
     },
     {
      'name': 'New Safe',
      'desc': 'Measure For Breakthroughs. Get Breakthroughs.',
      'imge': 'newsafe.jpg',
      'link': 'https://newsafels.com/',
      'show_order':'83'
     },
     {
      'name': 'HuntingtonLaw',
      'desc': 'Protect Yourself. Protect Your Wealth.',
      'imge': 'huntington.jpg',
      'link': 'http://huntingtonlaw.ca',
      'show_order':'84'
     }
   ]
   onScroll() { 
    //console.log('scrolled1111!!', this.visibleLimit);
    const start = this.sum;
    //this.sum += 2;
    //console.log(this.visibleLimit, this.showLimit, 'Tini');
    //var html='<div >Loading data...</div>',
    //el = document.getElementById('myID');
    if (this.visibleLimit <=  this.arrayy.length) {
      console.log(this.visibleLimit, this.showLimit, 'geethu'+ this.arrayy.length);
      
      this.loading = true;
      var loadingvar  = this;
      var vislim  = this;
      setTimeout(function() { console.log('ddd');
          //this.appendItems(start, this.sum);
          loadingvar.loading = false;
          loadingvar.visibleLimit +=9;
          //vislim += 3;
          
         }, 3000);
         
      
     
      // console.log(this.visibleLimit, this.showLimit, 'geethu'+ this.arrayy.length);
      // this.loading = true;
      // //console.log('Load Value',this.loading);
      // setTimeout(function() {
      //   //this.appendItems(start, this.sum);
      //   this.loading = false;
      //   console.log('Load Value2xx',this.loading);
      //  }, 6000);
      // this.direction = 'down';
      // this.visibleLimit += 3;
    } else {
      console.log(this.visibleLimit, this.showLimit, 'Else');
      this.loading  = false;
    }

  }
   addItems(startIndex, endIndex, _method) {

    
    for (let i = 0; i < 4; ++i) {
      this.array[_method]([i, ' ', 'Test'].join(''));
    }

    
  }
  // appendItems(startIndex, endIndex) {
  //   this.addItems(startIndex, endIndex, 'push');
  // }
 prependItems(startIndex, endIndex) {
    this.addItems(startIndex, endIndex, 'unshift');
  }

  onScrollDown (ev) {
    console.log('scrolled down!!', ev);

    // add another 20 items
    const start = this.sum;
    this.sum += 20;
    //this.appendItems(start, this.sum);
    this.direction = 'down';
  }
  onUp(ev) {
    console.log('scrolled up!', ev);
    const start = this.sum;
    this.sum += 20;
    this.prependItems(start, this.sum);
    this.direction = 'up';
  }
  generateWord() {
    // return chance.word();
  }

  toggleModal() {
    this.modalOpen = !this.modalOpen;
  }

  ngOnInit() {
    const body = document.getElementsByTagName('footer')[0];
    body.classList.add('webe_portfolio');

    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  sortArray(arr){
    const result = [];
    for(const res of arr) {
      result[ (parseInt(res.show_order)) - 1] = res;
    }
    return result;
  }
}
