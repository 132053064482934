import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.css']
})
export class HeaderTabComponent implements OnInit {

  public show = true;
   displaynum = 2;
  
  public type = 'component';

  public disabled = false;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: this.displaynum,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };


  websitedevelopment = false;
  graphicdesign:  any;
  leadgeneration:  any;
  whoweare:  any;
  apps: any;

  public innerWidth: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;
  colpath: any;
  colclass: any;
  colin: any;

  public slides = [
    'Who We Are',
    'Testimonials',
    'Charitable Contributions',
    'Portfolio',
  ];

  

  public leadgeneration_items = [
    'Social Media Optimization',
    'Build a Blog',
    'Lead Generation Tools'
  ];

  public graphicdesign_items = [
    {'name':'Website Design','link':'website-design'},
    {'name':'Mobile & Tablet Website Design','link':'mobile-and-tablet-website-design'},
    {'name':'Mobile App Design','link':'mobile-app-design'},
    {'name':'Binding & Marketing Material','link':'marketing-material'},
    {'name':'Digital Marketing','link':'digital-marketing'}
  ];

  public websitedevelopment_items = [
    {'name':'Company Website','link':'build-a-company-website'},
    {'name':'E-Commerce Website','link':'ecommerce-website-development'},
    {'name':'Cms','link':'content-management-system'},
    {'name':'Custom Website', 'link':'custom-website-development'},
    {'name':'Mobile & Tablet Website','link':'mobile-tablet-website-development'},
    {'name':'Website Applications','link':'website-application'},
    {'name':'Misc Website Development','link':'misc-website-design'}
  ];

  public apps_menu_items  = [
    {'name':'iPhone Application','link':'iphone-application'},
    {'name':'Android Application','link':'android-application'},
    {'name':'Hybrid Application','link':'hybrid-application'}
  ];

  // slider ends

  @HostListener('window:resize', ['$event'])
  onResize(event) { console.log('entered');
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = ''; this.displaynum = 3;
      if (this.innerWidth <= 375) { console.log('dd');
      this.displaynum = 2;
      } else {
        this.displaynum = 3;
      }
    }

    
  }
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
    }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
      if (this.innerWidth <= 375) {console.log('ee');
      this.displaynum = 2;
     } else {
      this.displaynum = 3;
     }
    }
    
    const upurl =  this.router.url;
this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
    const urlslug = this.activatedRoute.root.children[0]['_routerState'].snapshot.url;

    // tslint:disable-next-line:max-line-length
    if (urlslug ===  '/build-a-company-website' || urlslug ===  '/ecommerce-website-development' || urlslug ===  '/custom-website-development' || urlslug ===  '/content-management-system' || urlslug ===  '/mobile-tablet-website-development' || urlslug === '/misc-website-design') {
      this.graphicdesign = false;
      this.leadgeneration = false;
      this.whoweare = false;
      this.apps = false;
      this.websitedevelopment = true;
    }
    // tslint:disable-next-line:max-line-length
    if (urlslug ===  '/website-design' || urlslug ===  '/mobile-and-tablet-website-design' || urlslug ===  '/marketing-material' || urlslug ===  '/digital-marketing') {
      this.leadgeneration = false;
      this.whoweare = false;
      this.websitedevelopment = false;
      this.apps = false;
      this.graphicdesign = true;
    }
    // tslint:disable-next-line:max-line-length
    if (urlslug ===  '/social-media-optimization' || urlslug ===  '/build-blog' || urlslug ===  '/lead-generation-tools') {
      this.whoweare = false;
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.apps = false;
      this.leadgeneration = true;
    }
    // tslint:disable-next-line:max-line-length
    if (urlslug ===  '/iphone-application' || urlslug ===  '/android-application' || urlslug ===  '/facebook-application' || urlslug ===  '/hybrid-application' || urlslug ===  '/website-application') {
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.leadgeneration = false;
      this.whoweare = false;
      this.apps = true;
    }
    // tslint:disable-next-line:max-line-length
    if (urlslug ===  '/who-we-are' || urlslug ===  '/testimonials' || urlslug ===  '/charitable-contributions') {
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.leadgeneration = false;
      this.apps = false;
      this.whoweare = true;
    }

});
    // tslint:disable-next-line:max-line-length
    if (upurl ===  '/build-a-company-website' || upurl ===  '/ecommerce-website-development' || upurl ===  '/custom-website-development' || upurl ===  '/content-management-system' || upurl ===  '/mobile-tablet-website-development' || upurl === '/misc-website-design') {
        this.graphicdesign = false;
        this.leadgeneration = false;
        this.whoweare = false;
        this.apps = false;
        this.websitedevelopment = true;
      }
      // tslint:disable-next-line:max-line-length
    if (upurl ===  '/website-design' || upurl ===  '/mobile-and-tablet-website-design' || upurl ===  '/marketing-material' || upurl ===  '/digital-marketing') {
      this.leadgeneration = false;
      this.whoweare = false;
      this.websitedevelopment = false;
      this.apps = false;
      this.graphicdesign = true;
    }
    // tslint:disable-next-line:max-line-length
    if (upurl ===  '/social-media-optimization' || upurl ===  '/build-blog' || upurl ===  '/lead-generation-tools') {
      this.whoweare = false;
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.apps = false;
      this.leadgeneration = true;
    }
    // tslint:disable-next-line:max-line-length
    if (upurl ===  '/iphone-application' || upurl ===  '/android-application' || upurl ===  '/facebook-application' || upurl ===  '/hybrid-application' || upurl ===  '/website-application') {
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.leadgeneration = false;
      this.whoweare = false;
      this.apps = true;
    }
    // tslint:disable-next-line:max-line-length
    if (upurl ===  '/who-we-are' || upurl ===  '/testimonials' || upurl ===  '/charitable-contributions' ) {
      this.websitedevelopment = false;
      this.graphicdesign = false;
      this.leadgeneration = false;
      this.apps = false;
      this.whoweare = true;
    }
  }

  

}
