import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.css'],
})
export class FooterMainComponent implements OnInit {

  public innerWidth: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;
  slug: string;
  footer_sample = false;
  splitted: string[];
  country: string;
  main_slug: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  if (this.router.url) {
    this.slug = this.router.url.substr(1);
    this.splitted = this.slug.split('-', -1);
    this.country = this.splitted.pop();
    this.main_slug = this.slug.split('-' + this.country, -1)[0];
      // tslint:disable-next-line:max-line-length
      if (this.main_slug === 'free-website-design-electrician' || this.main_slug === 'free-website-design-plumber' || this.main_slug === 'free-website-design-contractor') {
        this.footer_sample = true;
      }
    }
  this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  
}
