import { Component, OnInit } from '@angular/core';
import { NgModule }            from '@angular/core';
import { FormGroup, FormControl, FormArray, NgForm, Validators } from '@angular/forms';
import { FormsModule }   from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model :any = {};
  
  constructor() { }

  ngOnInit() {
  }

  loginsubmit(){

  }

}
