import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
@Component({
  selector: 'app-header-top-navigation',
  templateUrl: './header-top-navigation.component.html',
  styleUrls: ['./header-top-navigation.component.css']
})
export class HeaderTopNavigationComponent implements OnInit {
  public innerWidth: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;
  colin: any;
  top_compliment: boolean;
  urlclass: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }
  constructor(private router: Router, private GlobalService: GlobalService) {
    this.top_compliment = true;     
  }

  ngOnInit() {
    this.GlobalService.setTopCompliment(this.top_compliment);
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 784) { //browser detect 784 on actual width 768
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
    if(this.router.url){
      this.urlclass = this.router.url.substr(1);
    }
  }
  
  
  drop_display  = false;
  top_display  = true;
  mouseOver(){
    this.drop_display = true;
  }
  mouseLeave(){
    this.drop_display = false;
  }
 topOver(){
    this.top_display = true;
  }
  topLeave(){
    this.top_display = true;
  }
  closecompliment(){

    this.top_compliment = false;
    this.GlobalService.setTopCompliment(this.top_compliment);
  }
}
