import { Component, OnInit, HostListener } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  public innerWidth: any;
  thedata: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;
  colin: any;

  currPos: Number = 0;
    startPos: Number = 0;
    changePos: Number = 100;
    navclass: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }

  @HostListener('window:scroll', [])
onWindowScroll() {
  this.currPos = (window.pageYOffset || 0);
        if(this.currPos >= this.changePos ) {
          //  console.log('add');
            this.navclass = 'withbg';
        } else {
            //console.log('ss');
            this.navclass = 'noo';
        }
}

  constructor() { }
  ngOnInit() {
    AOS.init();
    this.innerWidth = window.innerWidth;
    console.log('on load', this.innerWidth);
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }
  /* Set the width of the side navigation to 250px */
  openNav() {
    this.thedata = document.getElementById('menuclick').getAttribute('data-val');
    if (this.thedata === '1') {
      document.getElementById('mySidenav').style.width = '90%';
      document.getElementById('menuclick').setAttribute('data-val', '0');
    } else {
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById('menuclick').setAttribute('data-val', '1');
    }
}
}
