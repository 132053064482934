import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
     //add class to body
   	const body = document.getElementsByTagName('footer')[0];
    body.classList.add('webe_singlePort');
  }
  

}
