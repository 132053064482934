import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../services/admin/login.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  model:any = {};
  post:any;
  constructor(private forgotAuth: LoginService) { }

  ngOnInit() {
  }
  forgotsubmit(email: string) {
    this.forgotAuth.resetPassword(email)
  }
}
