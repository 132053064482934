import { Injectable } from '@angular/core';
import { Http, Response, Headers } from "@angular/http";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: Http) { }

  contactsubmit(form){
    var headers= new Headers();
    headers.append('Content-Type','application/X-www-form=urlencoded');    
    return this.http.post("https://webeteer.com/api/contact.php",form).pipe(map(res=>res.json()));    
  }


}
