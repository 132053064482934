import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public top_compliment = new Subject<any>();
  public top_compliment_land = new Subject<any>();
  constructor() { }
  setTopCompliment( val ) {
    this.top_compliment.next(val);
  }
  setTopLandcompliment( val ){
    this.top_compliment_land.next(val);
  }
}
