import { Component, OnInit , HostListener} from '@angular/core';
// import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';
import { FormsModule }   from '@angular/forms';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit {

  public search: any = '';
  locked: any[] = [];
  programming: any[]  = [];
  help: any[] = [];
  public innerWidth: any;
  fordesktop = false;
  formobile = false;
  displayClass: any;
  deviceClass: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }

  constructor() { }

  ngOnInit() {
    this.locked = [
      // tslint:disable-next-line:max-line-length
      {ID: 1, Blog: 'Page Speed', desc: 'Pаgе speed саn еаѕіlу bе defined as thе tіmе іt takes tо dіѕрlау the content on a specific wеb раgе. ', AuthID: '1', Author: 'Webeteer', blogcategory: 'Programming', slug: 'page-speed', imge: 'new-page-speed.png'},
      {ID: 2, Blog: 'Webeteer\'s Website Development Process', desc: 'Webeteer follows a very strict development procedure, a development procedure that has led...', AuthID: '1', Author: 'Webeteer', blogcategory: 'Help', slug: 'webeteers-website-development-process', imge: 'webeteer-website-blog.png'},
      // tslint:disable-next-line:max-line-length
      // {ID: 2, Blog: 'Inenim justo rhoncus imperdiet venenatis vitae justo', desc: 'Test Content', AuthID: '68114', FormName: 'Fellman', WinHandle: 'Oak Way'}
  ];
    this.programming  = [
      {ID: 3, Blog: 'Page Speed', desc: 'Pаgе speed саn еаѕіlу bе defined as thе tіmе іt takes tо dіѕрlау the content on a specific wеb раgе. ', AuthID: '1', Author: 'Webeteer', blogcategory: 'Programming',slug: 'page-speed' , imge: 'img_blog_4.jpg'},
      {ID: 4, Blog: 'Webeteer\'s Website Development Process', desc: 'Webeteer follows a very strict development procedure, a development procedure that has led...', AuthID: '1', Author: 'Webeteer', blogcategory: 'Help',slug: 'webeteers-website-development-process' , imge : 'webeteer-wesite-blog2.png'},
    ];
    this.help  = [
      {ID: 2, Blog: 'Webeteer\'s Website Development Process', desc: 'Webeteer follows a very strict development procedure, a development procedure that has led...', AuthID: '1', Author: 'Webeteer', blogcategory: 'Help',slug: 'webeteers-website-development-process' , imge : 'webeteer-wesite-blog2.png'},
    ];

  this.innerWidth = window.innerWidth;
  if (this.innerWidth > 768) {
    this.deviceClass  = 'desktop';
    this.fordesktop   = true;
    this.formobile = false;
  } else {
    this.deviceClass  = 'mobile';
    this.fordesktop = false;
    this.formobile = true;
  }
  }

  frstload1	=	true;
  frstload2	=	false;
  frstload3	=	false;

  load_btn1  = true;
  load_btn2  = false;
  load_btn3  = false;


	portfolio_1(){
		this.frstload1	=	true;
    this.frstload2	=	true;
    this.frstload3	=	false;

    this.load_btn1	=	false;
    this.load_btn2	=	true;
    this.load_btn3	=	false;



	}
	portfolio_2(){
		this.frstload1	=	true;
    this.frstload2	=	true;
    this.frstload3	=	true;

    this.load_btn1	=	false;
    this.load_btn2	=	false;
    this.load_btn3	=	true;
  }
  }
