import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers } from "@angular/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  isloggedin:boolean=false;

  constructor(private http: Http) { }
  logined: boolean = false;
  username:string="";

  setLoggedin(logged){
    this.isloggedin=logged;
  }

login(username,password){
    var headers= new Headers();
    headers.append('Content-Type','application/X-www-form=urlencoded');

     return this.http.post("http://localhost/webeteer_oursite/webeteer_june18/api/admin-login.php",{"username":username,"password":password}).pipe(map(res=>res.json()));

  }
 
  logout() {
    localStorage.removeItem('currentUser');
  }
  resetPassword(email: string) {
   // var auth = firebase.auth();
    //return auth.sendPasswordResetEmail(email)
      //.then(() => console.log("email sent"))
      //.catch((error) => console.log(error))
  }
  

}
