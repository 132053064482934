import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newportfolio',
  templateUrl: './newportfolio.component.html',
  styleUrls: ['./newportfolio.component.css']
})
export class NewportfolioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
