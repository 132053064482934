import { Component, OnInit } from '@angular/core';
import { Router,  NavigationExtras,ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../services/admin/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  model:any = {};
  post:any;
  username:string;
  password:string;
  msgError:string;
  error:boolean=false;
  users:{
    loginName:string,
    loginPass:string
  
  };
  error_message="";
  success_message="";
  constructor(private loginAuth: LoginService,public router: Router,private route: ActivatedRoute) {
    this.username="";
    this.password="";
   }

  ngOnInit() {
  }
  loginsubmit(post){
    this.username=post.username;
    this.password=post.password;

    this.loginAuth.login(this.username,this.password).subscribe(users=>{
      this.users=users;
      this.loginAuth.setLoggedin(false);
      localStorage.setItem('currentUser', users.name);
      let successBar = document.querySelector(".msgError");
      successBar.classList.remove("alert-danger");
      successBar.classList.add("alert-success");
      this.success_message="Your successfully loggedin";
      this.msgError = this.success_message;
      this.router.navigate(["admin/dashboard"]);
     
    },
    error=>{
      this.error=!this.error;
      let errorBar = document.querySelector(".msgError");
      errorBar.classList.remove("alert-success");
      errorBar.classList.add("alert-danger");
      this.error_message="Username or password invalid";
      this.msgError = this.error_message;

    }
  );
  
 
  }
}
