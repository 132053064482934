import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-company-website',
  templateUrl: './company-website.component.html',
  styleUrls: ['./company-website.component.css']
})
export class CompanyWebsiteComponent implements OnInit {
  public innerWidth: any;
  fordesktop = false;
  formobile = false;
  displayClass: any;
  deviceClass: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  constructor( private router: Router ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }
  noProblemIfLinkIsWorkingOnIphone( link ){
    this.router.navigate([link]);
  }

}
