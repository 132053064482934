import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalService } from '../../../services/global.service';
@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.css']
})
export class HeaderNavigationComponent implements OnInit {
  public innerWidth: any;
  thedata: any;
  deviceClass: any;
  fordesktop = false;
  formobile = false;
  /*For display*/
  fordisplay  = true;
  displayclass: any;

  colpath: any;
  colclass: any;
  colin: any;
  top_compliment:boolean;
  top_compliment_land: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router, private GlobalService: GlobalService) {

      this.GlobalService.top_compliment.subscribe( data => {
        this.top_compliment = data;
      });
      this.GlobalService.top_compliment_land.subscribe( data => {
        this.top_compliment_land = data;
      });
  }

  ngOnInit() {
    const upurl =  this.router.url;
    /*Header element chnages*/
    if (upurl  === '/privacy-policy' || upurl  === '/who-we-are') {
      this.fordisplay = false;
      this.displayclass = 'showthis';
    } else {
      this.displayclass = '';
    }
    if (upurl  === '/portfolio-gallery') {
      this.colpath = false;
      this.colclass = '';
      this.colin  = 'in';
    } else {
      this.colpath = true;
      this.colclass = '';
      this.colin  = 'in';
    }
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(x => {
      const urlslug = this.activatedRoute.root.children[0]['_routerState'].snapshot.url;
      if (urlslug  === '/portfolio-gallery') {
        this.colpath = false;
        this.colclass = 'collapsed';
        this.colin  = '';
      } else {
        this.colpath = true;
        this.colclass = '';
        this.colin  = 'in';
      }
    });

    this.innerWidth = window.innerWidth;
    console.log('on load', this.innerWidth);

    if (this.innerWidth >= 784) { //browser detect 784 on actual width 768
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
      this.colin  = 'in';
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
      this.colin  = '';
    }
  }

  /* Set the width of the side navigation to 250px */
 openNav() {
if(this.top_compliment_land === undefined){}
else{
  if(this.top_compliment_land == true){
  document.getElementById('mySidenav').style.top = '170px';}
  else{
  document.getElementById('mySidenav').style.top = '100px';
}
}
if(this.top_compliment === undefined){}
else{
  if(this.top_compliment == true)
  document.getElementById('mySidenav').style.top = '170px';
  else
  document.getElementById('mySidenav').style.top = '100px';
}
    this.thedata = document.getElementById('menuclick').getAttribute('data-val');
    if (this.thedata === '1') {
      document.getElementById('mySidenav').style.width = '100%';
      document.getElementById('menuclick').setAttribute('data-val', '0');
    } else {
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById('menuclick').setAttribute('data-val', '1');
    }

}

}
