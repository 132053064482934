import { Component, OnInit, HostListener  } from '@angular/core';
import { ViewChild,ViewContainerRef,ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
// import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { RequestSampleService } from '../../services/request-sample.service';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-request-sample',
  templateUrl: './request-sample.component.html',
  styleUrls: ['./request-sample.component.css']
})

export class RequestSampleComponent implements OnInit {
public innerWidth: any;
  deviceClass: any;
  fordesktop = false;
	formobile = false;
	error_message = "";
  success_message = "";
  public modalRef: BsModalRef; // {1}
  config={
	  class:"free-sample-popupsec",
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768) {
      this.deviceClass  = 'desktop';
      this.fordesktop   = true;
      this.formobile = false;
    } else {
      this.deviceClass  = 'mobile';
      this.fordesktop = false;
      this.formobile = true;
    }
  }

	//@ViewChild('clone') template;
	// Where to insert the cloned content
	//@ViewChild('container', {read:ViewContainerRef}) container: any;
	
	// users:FirebaseListObservable<any>;
	// constructor(private resolver:ComponentFactoryResolver,private router:Router,public afAuth: AngularFireAuth, public af: AngularFireDatabase){
	constructor(private formBuilder: FormBuilder,public requestObject:RequestSampleService,private resolver:ComponentFactoryResolver,private router:Router,private modalService: BsModalService){
		// this.users = af.list('users');
	}
	public openModal(freesample:any) {
		this.modalRef = this.modalService.show(freesample,  this.config); // {3}
	  }


    cloneTemplate(){
		this.website_addresses.push(this.formBuilder.control('',Validators.required));
        //const view = this.container.createEmbeddedView(this.template);
        //this.container.insert(view);
   	}
   	removeTemplate(index){
		// control refers to your formarray
    	const control = <FormArray>this.form.controls['website_addresses'];
    	// remove the chosen row
    	control.removeAt(index);
	}
	get website_addresses() {
		return this.form.get('website_addresses') as FormArray;
	}
   	// request form

  	form;
  	step1Form;
  	requestsubmit(){
		if(!this.form.invalid){
			this.requestObject.sendRequest(this.form.value).subscribe(request=>{						
				let successBar = document.querySelector(".error_message");
				successBar.classList.remove("alert-danger");
				successBar.classList.add("alert-success");
				this.router.navigate(['/thankyou']);    				
				// this.success_message="<h3>Wohoo! We've received your Free Sample Inquiry.</h3><p>As soon as it's ready, We'll share it to you via Facebook and send a copy to your e-mail.</p>";
				// this.error_message = this.success_message;
				// this.form.reset();
				},);
		}
	}

	ngOnInit() {
		this.innerWidth = window.innerWidth;
		this.innerWidth = window.innerWidth;
		if (this.innerWidth > 768) {
		  this.deviceClass  = 'desktop';
		  this.fordesktop   = true;
		  this.formobile = false;
		} else {
		  this.deviceClass  = 'mobile';
		  this.fordesktop = false;
		  this.formobile = true;
		}


		this.form = this.formBuilder.group({
			project_name: ['', Validators.required],
			new_project: ['', Validators.required],
			design_idea: ['', Validators.required],
			mobile_graphic: ['', Validators.required],
			design_inspir: ['', Validators.required],
			website_addresses: this.formBuilder.array([
				this.formBuilder.control('',Validators.required)
			]),		
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			phone: ['', Validators.required],

		});

	}

  	step1	=	true;
	step2	=	false;
	step3	=	false;
	step4	=	false;
	step5	=	false;
	step6	=	false;
	step7	=	false;

	request_1(){
		this.step1	=	false;
		this.step2	=	true;
		this.step3	=	false;
		this.step4	=	false;
		this.step5	=	false;
		this.step6	=	false;
		this.step7	=	false;
		let progressBar = document.querySelector(".request2");
		progressBar.classList.add("top_line");
	
	}
	request_2(){
		this.step1	=	false;
		this.step2	=	false;
		this.step3	=	true;
		this.step4	=	false;
		this.step5	=	false;
		this.step6	=	false;
		this.step7	=	false;
		let progressBar = document.querySelector(".request3");
		progressBar.classList.add("top_line");
	}
	request_3(){
		this.step1	=	false;
		this.step2	=	false;
		this.step3	=	false;
		this.step4	=	true;
		this.step5	=	false;
		this.step6	=	false;
		this.step7	=	false;
		let progressBar = document.querySelector(".request4");
		progressBar.classList.add("top_line");
	}
	request_4(){
		this.step1	=	false;
		this.step2	=	false;
		this.step3	=	false;
		this.step4	=	false;
		this.step5	=	true;
		this.step6	=	false;
		this.step7	=	false;
		let progressBar = document.querySelector(".request5");
		progressBar.classList.add("top_line");
	}
	request_5(){
		this.step1	=	false;
		this.step2	=	false;
		this.step3	=	false;
		this.step4	=	false;
		this.step5	=	false;
		this.step6	=	true;
		this.step7	=	false;
		let progressBar = document.querySelector(".request6");
		progressBar.classList.add("top_line");
	}
	request_6(){
		this.step1	=	false;
		this.step2	=	false;
		this.step3	=	false;
		this.step4	=	false;
		this.step5	=	false;
		this.step6	=	false;
		this.step7	=	true;
		let progressBar = document.querySelector(".request7");
		progressBar.classList.add("top_line");
	}
	

}
