import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { RegisterServiceService } from '../../services/register-service.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  model :any = {};
  post:any;
  cname:string;
  cemail:string;
  phone:string;
  company_name:string;
  country:string;
  url:string;
  email_notification_progress:boolean;
  email_notification_completed:boolean;
  email_notification_comfeed:boolean;
  msgError:string;
  error:boolean = false;

  users:{
    registerName:string;
    registerEmail:string;
    registerPhone:string;
    registerCompany:string;
    registerCountry:string;
    registerUrl:string;
    registerEmailProgress:boolean;
    registerEmailCompleted:boolean;
    registerEmailComFeed:boolean;
  };

  error_message = "";
  success_message = "";


  constructor(private registerFunction: RegisterServiceService,public router: Router,private route: ActivatedRoute) {
    this.cname  = "";
    this.cemail = "";
    this.phone  = "";
    this.company_name = "";
    this.country  = "";
    this.url  = "";
    this.email_notification_progress  = false;
    this.email_notification_completed = false;
    this.email_notification_comfeed   = false;

   }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('register');
  }

  step1 = true;
  step2 = false;
  step3	= false;

  step_1(){
    this.step1 = false;
    this.step3 = false;
  	this.step2 = true;
  }

  step_2(){ 
  	this.step1 = false;
    this.step2 = false;
  	this.step3 = true;
  } 
myFunc()
{
  
}
registerSubmit(post){
    console.log('ssss0');
    this.cname  = post.cname;
    this.cemail = post.cemail;
    this.phone  = post.phone;
    this.company_name = post.company_name;
    this.country  = post.country;
    this.url  = post.url;
    this.email_notification_progress  = post.email_notification_progress;
    this.email_notification_completed = post.email_notification_completed;
    this.email_notification_comfeed   = post.email_notification_comfeed;


    // this.registerFunction.register(this.cname,this.cemail,this.phone,this.company_name,this.country,this.url,this.email_notification_progress,this.email_notification_completed,this.email_notification_comfeed).subscribe(users=>{
    //   this.users=users;
    //   let successBar = document.querySelector(".msgError");
    //   successBar.classList.remove("alert-danger");
    //   successBar.classList.add("alert-success");
    //   this.success_message="Your successfully loggedin";
    //   this.msgError = this.success_message;
    //   this.router.navigate(["admin/dashboard"]);
    //   },
    //   // error=>{
    //   // this.error=!this.error;
    //   // let errorBar = document.querySelector(".msgError");
    //   // errorBar.classList.remove("alert-success");
    //   // errorBar.classList.add("alert-danger");
    //   // this.error_message="Username or password invalid";
    //   // this.msgError = this.error_message;
      
    //   // }
    //   );
}
}
